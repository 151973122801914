import { useSelector } from "react-redux";
import Layout from "../../../components/layout";
import ExpensesTable from "../components/ExpensesTable";
import { useEffect, useState } from "react";
import useExpenses from "../hooks/useExpenses";
import ExpenseModal from "../components/ExpenseModal";

export default function ExpensesRoute() {
	const expensesState = useSelector((state) => state.expenses);

	const [showNewExpenseModal, setShowNewExpenseModal] = useState(false);

	const { getExpenses } = useExpenses();

	useEffect(() => {
		if (!expensesState.loaded) {
			getExpenses();
		}
	}, [expensesState.loaded, getExpenses]);

	return (
		<Layout title='Expenses'>
			<div className='flex justify-end'>
				<button
					className='px-4 py-2 bg-dashDarkBlue text-dashBlue rounded-xl shadow-lg'
					onClick={() => setShowNewExpenseModal(true)}
				>
					Create Expense
				</button>
			</div>
			{expensesState.loaded && expensesState.expenses?.length > 0 && (
				<ExpensesTable expenses={expensesState.expenses} />
			)}
			{showNewExpenseModal && <ExpenseModal type='NEW' setShowModal={setShowNewExpenseModal} />}
		</Layout>
	);
}
