import React, { useEffect, useState } from "react";
import Table from "../../../components/Table";
import TextField from "../../../components/ui/TextField";
import SelectField from "../../../components/ui/SelectField";
import DateField from "../../../components/ui/DateField";
import { useDispatch, useSelector } from "react-redux";
import { postProject, putProject } from "../../../redux/actions/projectActions";
import { toast } from "react-toastify";
import { resetHandler } from "../../../redux/actions/pendingActions";
import { PUT_PROJECT, POST_PROJECT } from "../../../redux/constants/projectConstants";
import CurrencyField from "../../../components/ui/CurrencyField";
import FlexRowBetween from "../../../components/structural/FlexRowBetween";
import FlyoverTemplate from "../../../components/modals/FlyoverTemplate";
import Button from "../../../components/ui/Button";
import { formattedDate } from "../../../utils/formattedDate";
import ConfirmActionModal from "../../../components/ConfirmActionModal";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { deleteTransaction } from "../../../redux/actions/transactionActions";
import IncomeModal from "../../../features/income/components/IncomeModal";

function ProjectModal({ setShowModal, type, editProject }) {
	// Component State
	const [showPaymentModal, setShowPaymentModal] = useState(false);
	const [showEditPaymentModal, setShowEditPaymentModal] = useState(false);
	const [showConfirmDeleteTransaction, setShowConfirmDeleteTransaction] = useState(false);
	const [transactionToEdit, setTransactionToEdit] = useState(null);
	const [transactionToDelete, setTransactionToDelete] = useState(null);
	const [project, setProject] = useState({
		id: "",
		title: "",
		associatedClient: "",
		// status: "",
		source: "",
		quote: 0,
		dateCompleted: new Date(),
	});

	// Redux State
	const clients = useSelector((state) => state.clients);
	const pendingState = useSelector((state) => state.pendingStates);
	const transactions = useSelector((state) => state.transactions);

	// Const Declerations
	const clientOptions = clients.map((item) => {
		return {
			value: item.id,
			label: item.clientName,
		};
	});

	const projectTransactions = transactions.filter(
		(transaction) => transaction.associatedProject === project.id
	);

	const dispatch = useDispatch();

	// Function Declerations
	const onCloseHandler = () => {
		setShowModal(false);
	};

	const onSaveHandler = () => {
		if (project.title.trim() === "") {
			toast.warning("Please enter project title");
			return;
		}
		if (project.associatedClient.trim() === "") {
			toast.warning("Please select a client");
			return;
		}
		if (type === "NEW") {
			dispatch(postProject(project));
		} else if (type === "EDIT") {
			dispatch(putProject(project));
		}
	};

	const editTransactionHandler = (transaction) => {
		setTransactionToEdit(transaction);
		setShowEditPaymentModal(true);
	};

	const deleteTransactionHandler = (transaction) => {
		setTransactionToDelete(transaction);
		setShowConfirmDeleteTransaction(true);
	};

	// Transaction Table
	const transactionTableColumns = [
		{
			title: "Value",
		},
		{
			title: "Date",
		},
		{
			title: "Category",
		},
		{
			title: "Actions",
		},
	];

	const transactionTableRows = projectTransactions.map((transaction) => ({
		fields: [
			{
				value: `£${(transaction.value / 100).toFixed(2)}`,
			},
			{
				value: formattedDate(transaction.dateTransaction),
			},
			{
				value: transaction.category,
			},
			{
				value: (
					<div className='flex items-center justify-center space-x-4'>
						<button onClick={() => deleteTransactionHandler(transaction)}>
							<DeleteOutlined />
						</button>
						<button onClick={() => editTransactionHandler(transaction)}>
							<EditOutlined />
						</button>
					</div>
				),
			},
		],
	}));

	// Effect Declerations

	// if type is edit effect adds the project to edit into state
	useEffect(() => {
		if (type === "EDIT") {
			setProject({
				id: editProject.id,
				title: editProject.title,
				associatedClient: editProject.associatedClient,
				status: editProject.status,
				source: editProject.source,
				quotePrice: editProject.quotePrice / 100,
				dateCompleted: editProject.dateCompleted ? new Date(editProject.dateCompleted) : new Date(),
			});
		}
	}, [editProject, type]);

	// close form on success
	useEffect(() => {
		if (type === "NEW" && pendingState.POST_PROJECT?.loaded) {
			setShowModal(false);
			dispatch(resetHandler(POST_PROJECT));
		} else if (type === "EDIT" && pendingState.PUT_PROJECT?.loaded) {
			setShowModal(false);
			dispatch(resetHandler(PUT_PROJECT));
		}
	});

	return (
		<FlyoverTemplate
			title={type === "NEW" ? "Create Project" : "Update Project"}
			onClose={() => onCloseHandler()}
			buttonOne={{ label: "Save", onClick: () => onSaveHandler() }}
			buttonTwo={{ label: "Cancel", onClick: () => onCloseHandler() }}
		>
			<FlexRowBetween width='w-full'>
				<TextField
					label='Project Title'
					value={project.title}
					onChange={(e) => setProject({ ...project, title: e.target.value })}
				/>
				<SelectField
					label='Status'
					options={[
						{ label: "Quotation", value: "QUOTE" },
						{ label: "Proposal Submitted", value: "PROPOSAL" },
						{ label: "Ordered - Not Started", value: "ORDERED" },
						{ label: "Work in Progress", value: "WIP" },
						{ label: "Completed", value: "COMPLETE" },
					]}
					value={project.status}
					onChange={(e) => setProject({ ...project, status: e.target.value })}
					placeholder='Select status...'
				/>
			</FlexRowBetween>
			<FlexRowBetween width='w-full'>
				<SelectField
					label='Client'
					options={clientOptions}
					value={project.associatedClient}
					onChange={(e) => setProject({ ...project, associatedClient: e.target.value })}
					placeholder='Select client...'
				/>
				<SelectField
					label='Order Source'
					options={[
						{ label: "Fiverr", value: "Fiverr" },
						{ label: "Website Query", value: "Website Query" },
						{ label: "Referral", value: "Referral" },
					]}
					value={project.source}
					onChange={(e) => setProject({ ...project, source: e.target.value })}
				/>
			</FlexRowBetween>
			<FlexRowBetween width='w-full'>
				<CurrencyField
					label='Quoted Price'
					value={project.quotePrice}
					onChange={(e) => setProject({ ...project, quotePrice: e.target.value })}
				/>
				<DateField
					label='Date Completed'
					value={project.dateCompleted}
					onChange={(date) => setProject({ ...project, dateCompleted: date })}
				/>
				<Button label='Add Payment' onClick={() => setShowPaymentModal(true)} />
			</FlexRowBetween>
			<FlexRowBetween width='w-full'>
				<Table columns={transactionTableColumns} rows={transactionTableRows} />
			</FlexRowBetween>
			{showPaymentModal && (
				<IncomeModal
					type='NEW'
					setShowModal={setShowPaymentModal}
					category='PROJECT'
					projectId={project.id}
					clientId={project.associatedClient}
				/>
			)}
			{showEditPaymentModal && (
				<IncomeModal
					type='EDIT'
					setShowModal={setShowEditPaymentModal}
					editTransaction={transactionToEdit}
				/>
			)}
			{showConfirmDeleteTransaction && (
				<ConfirmActionModal
					label='Confirm you wish to delete transaction?'
					onConfirm={() => {
						dispatch(deleteTransaction(transactionToDelete));
						setShowConfirmDeleteTransaction(false);
					}}
					onCancel={() => setShowConfirmDeleteTransaction(false)}
				/>
			)}
		</FlyoverTemplate>
	);
}

export default ProjectModal;
