export const mapStageToString = (status) => {
  switch (status) {
    case "QUOTE":
      return "Quotation";
    case "ORDER":
      return "Order Received";
    case "RETAINER":
      return "Active Client";
    case "DORMANT":
      return "Dormant Client";
    default:
      return "Unknown";
  }
};

export const mapProjectStatusToString = (status) => {
  switch (status) {
    case "QUOTE":
      return "Quotation";
    case "PROPOSAL":
      return "Proposal Submitted";
    case "WIP":
      return "Work in Progress";
    case "COMPLETE":
      return "Completed";
    default:
      return "Unknown";
  }
};
