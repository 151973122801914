import * as t from "../constants/clientConstants";
import {
  authenticatedDeleteRequest,
  authenticatedGetRequest,
  authenticatedPostRequest,
  authenticatedPutRequest,
} from "../helpers";

const clientRoute = "/client";

export const getClients = () => async (dispatch) => {
  authenticatedGetRequest(
    dispatch,
    t.GET_CLIENTS,
    clientRoute,
    {
      toast: false,
    },
    { toast: true, message: "Error retrieving clients" }
  );
};

export const postClient = (client) => async (dispatch) => {
  authenticatedPostRequest(
    dispatch,
    t.POST_CLIENT,
    clientRoute,
    { client },
    { toast: true, message: "Client Saved" },
    { toast: true, message: "Error saving client" }
  );
};

export const putClient = (client) => async (dispatch) => {
  authenticatedPutRequest(
    dispatch,
    t.PUT_CLIENT,
    clientRoute,
    { client },
    { toast: true, message: "Client Saved" },
    { toast: true, message: "Error saving client" }
  );
};

export const deleteClient = (client) => async (dispatch) => {
  authenticatedDeleteRequest(
    dispatch,
    t.DELETE_CLIENT,
    clientRoute,
    {
      client,
    },
    { toast: true, message: "Client Deleted" },
    { toast: true, message: "Error deleting client" }
  );
};
