import React, { useEffect, useState } from "react";
import Layout from "../../components/layout";
import FlexRowBetween from "../../components/structural/FlexRowBetween";
import { checkLoaded, checkPending } from "../../utils/checkPendingStatus";
import { getLeads } from "../../redux/actions/leadActions";
import { GET_LEADS } from "../../redux/constants/leadConstants";
import Button from "../../components/ui/Button";
import LeadTable from "./components/LeadTable";
import LeadModal from "./components/LeadModal";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../../components/ui/LoadingSpinner";

export default function LeadsScreen() {
	const [showAddLead, setShowAddLead] = useState(false);
	const pendingState = useSelector((state) => state.pendingStates);

	const dispatch = useDispatch();

	// If leads not in redux state calls get projects
	useEffect(() => {
		if (!checkLoaded(GET_LEADS, pendingState) && !checkPending(GET_LEADS, pendingState)) {
			dispatch(getLeads());
		}
	}, [pendingState, dispatch]);

	return (
		<Layout title='Leads'>
			<FlexRowBetween>
				<p></p>
				<div className='flex w-full lg:w-5/12'>
					<Button label='Add Lead' onClick={() => setShowAddLead(true)} />
				</div>
			</FlexRowBetween>
			{checkPending(GET_LEADS, pendingState) && <LoadingSpinner message='Loading leads' />}
			{checkLoaded(GET_LEADS, pendingState) && <LeadTable />}
			{showAddLead && <LeadModal setShowModal={setShowAddLead} type='NEW' />}
		</Layout>
	);
}
