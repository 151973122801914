export const formattedElapsedTime = (elapsedTime) => {
	const hours = Math.floor(elapsedTime / 3600);
	const minutes = Math.floor((elapsedTime % 3600) / 60);
	const seconds = elapsedTime % 60;

	return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(
		seconds
	).padStart(2, "0")}`;
};

export const getElapsedTime = (start, end) => {
	const endTime = new Date(end);
	const startTime = new Date(start);
	const elapsedSeconds = Math.floor((endTime - startTime) / 1000);
	return elapsedSeconds;
};
