import React from "react";
import FlexRowBetween from "../structural/FlexRowBetween";
import Button from "../ui/Button";

function ModalButtons({ buttonOne, buttonTwo }) {
  return (
    <FlexRowBetween width='w-full lg:w-8/12'>
      <Button
        label={buttonOne.label}
        onClick={buttonOne.onClick}
        disabled={buttonOne.disabled}
      />
      <Button
        label={buttonTwo.label}
        onClick={buttonTwo.onClick}
        disabled={buttonTwo.disabled}
      />
    </FlexRowBetween>
  );
}

export default ModalButtons;
