import React from "react";
import Layout from "../../components/layout";

function InboxScreen() {
  return (
    <Layout title='Inbox'>
      <p>I am Inbox</p>
    </Layout>
  );
}

export default InboxScreen;
