import React, { useState } from "react";
import Table from "../../../components/Table";
import { formattedDate } from "../../../utils/formattedDate";
import { EditOutlined } from "@ant-design/icons";
import SubscriptionsModal from "./SubscriptionsModal";
import { useSelector } from "react-redux";

export default function SubscriptionsTable({ subscriptions }) {
	const [showEditSubscription, setShowEditSubscription] = useState(false);
	const [subscriptionToEdit, setSubscriptionToEdit] = useState(null);
	const clients = useSelector((state) => state.clients);
	subscriptions = subscriptions
		.slice()
		.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
	const editSubscriptionHandler = (subscription) => {
		setSubscriptionToEdit(subscription);
		setShowEditSubscription(true);
	};

	const clientFromId = (id) => {
		const client = clients?.filter((client) => client.id === id);
		const clientName = client ? client[0]?.clientName : "";
		return clientName;
	};

	return (
		<div className='flex flex-wrap mt-4 rounded-t-xl overflow-clip'>
			{subscriptions && (
				<Table
					columns={[
						{ title: "Client" },
						{ title: "Description" },
						{ title: "Start Date" },
						{ title: "End Date" },
						{ title: "Fee" },
						{ title: "Billing Frequency" },
						{ title: "Status" },
						{ title: "Actions" },
					]}
					rows={subscriptions.map((subscription) => ({
						fields: [
							{ value: clientFromId(subscription.associatedClient) },
							{ value: subscription.description },
							{ value: formattedDate(subscription.startDate) },
							{ value: subscription.endDate ? formattedDate(subscription.endDate) : "-" },
							{
								value: `${
									subscription.feeCurrency === "GBP"
										? "£"
										: subscription.feeCurrency === "USD"
										? "$"
										: ""
								}${(subscription.fee / 100).toFixed(2)}`,
							},
							{ value: subscription.billingFrequency },
							{ value: subscription.status },
							{
								value: (
									<div className='flex items-center justify-center space-x-4'>
										<button onClick={() => editSubscriptionHandler(subscription)}>
											<EditOutlined />
										</button>
									</div>
								),
							},
						],
					}))}
				/>
			)}
			{showEditSubscription && (
				<SubscriptionsModal
					type='EDIT'
					setShowModal={setShowEditSubscription}
					editSubscription={subscriptionToEdit}
				/>
			)}
		</div>
	);
}
