import React, { useEffect, useState } from "react";
import ModalTemplate from "../../../components/modals/ModalTemplate";
import FlexRowBetween from "../../../components/structural/FlexRowBetween";
import CurrencyField from "../../../components/ui/CurrencyField";
import DateField from "../../../components/ui/DateField";
import SelectField from "../../../components/ui/SelectField";
import ModalButtons from "../../../components/modals/ModalButtons";
import useExpenses from "../hooks/useExpenses";
import TextField from "../../../components/ui/TextField";

export default function ExpenseModal({ type, setShowModal, editExpense }) {
	const [quickAdd, setQuickAdd] = useState("");
	const [expense, setExpense] = useState({
		id: "",
		associatedCompany: "",
		payee: "",
		type: "",
		category: "",
		value: 0,
		dateExpense: new Date(),
		description: "",
	});

	const { isLoading, actionSuccessful, createExpense, updateExpense } = useExpenses();

	const quickAddOptions = [
		{ label: "Hetzner Hosting Fee", value: "Hetzner-Hosting" },
		{ label: "Stripe Invoice Fee", value: "Stripe-Invoice" },
		{ label: "Stripe Processing Fee", value: "Stripe-Processing" },
		{ label: "Stripe Subscription Fee", value: "Stripe-Subscription" },
	];

	const onSaveHandler = async () => {
		if (type === "NEW") {
			await createExpense(expense);
		} else if (type === "EDIT") {
			await updateExpense(expense);
		}
	};

	const onCloseHandler = () => {
		setShowModal(false);
	};

	// if type is edit effect adds the expense to edit into state
	useEffect(() => {
		if (type === "EDIT") {
			setExpense({
				id: editExpense.id,
				associatedCompany: editExpense.associatedCompany,
				payee: editExpense.payee,
				type: editExpense.type,
				category: editExpense.category,
				value: editExpense.value / 100,
				dateExpense: editExpense.dateExpense ? new Date(editExpense.dateExpense) : new Date(),
				description: editExpense.description,
			});
		}
	}, [editExpense, type]);

	useEffect(() => {
		if (actionSuccessful) {
			setShowModal(false);
		}
	}, [actionSuccessful, setShowModal]);

	useEffect(() => {
		if (quickAdd) {
			switch (quickAdd) {
				case "Hetzner-Hosting":
					setExpense({
						...expense,
						payee: "Hetzner Online GmbH",
						type: "Business",
						category: "Hosting",
						description: "Monthly fee for servers",
					});
					break;
				case "Stripe-Invoice":
					setExpense({
						...expense,
						payee: "Stripe",
						type: "Business",
						category: "Financial",
						description: "Invoice Fee",
					});
					break;

				case "Stripe-Processing":
					setExpense({
						...expense,
						payee: "Stripe",
						type: "Business",
						category: "Financial",
						description: "Processing Fee",
					});
					break;

				case "Stripe-Subscription":
					setExpense({
						...expense,
						payee: "Stripe",
						type: "Business",
						category: "Financial",
						description: "Subscription Fee",
					});
					break;
				default:
					break;
			}
		}
	}, [quickAdd]);

	return (
		<ModalTemplate title={type === "NEW" ? "Create Expense" : "Expense"} onClose={onCloseHandler}>
			<FlexRowBetween width='w-full'>
				<SelectField
					label='Quick Add'
					options={quickAddOptions}
					value={quickAdd}
					onChange={(e) => setQuickAdd(e.target.value)}
					placeholder='Select expense type'
				/>
			</FlexRowBetween>
			<FlexRowBetween width='w-full'>
				<CurrencyField
					label='Value'
					value={expense.value}
					onChange={(e) => setExpense({ ...expense, value: e.target.value })}
				/>
				<DateField
					label='Date Expense'
					value={expense.dateExpense}
					onChange={(date) => setExpense({ ...expense, dateExpense: date })}
				/>
				<TextField
					label='Payee'
					value={expense.payee}
					onChange={(e) => setExpense({ ...expense, payee: e.target.value })}
				/>
			</FlexRowBetween>
			<FlexRowBetween width='w-full'>
				<SelectField
					label='Expense Type'
					options={[
						{ label: "Business Expense", value: "Business" },
						{ label: "Client Related Expense", value: "Client" },
					]}
					value={expense.type}
					onChange={(e) => setExpense({ ...expense, type: e.target.value })}
					placeholder='Select expense type'
				/>
				<SelectField
					label='Expense Category'
					options={[
						{ label: "Financial Costs", value: "Financial" },
						{ label: "Hosting Costs", value: "Hosting" },
						{ label: "Marketing Costs", value: "Marketing" },
					]}
					value={expense.category}
					onChange={(e) => setExpense({ ...expense, category: e.target.value })}
					placeholder='Select expense category'
				/>
			</FlexRowBetween>
			<div className='flex flex-col lg:flex-row items-center w-full mt-2'>
				<div className='flex flex-col w-full mt-2 mb-4 mx-4 grow'>
					<label className='mb-2'>Description</label>
					<textarea
						className='p-2 mt-2 h-20 border rounded-lg'
						value={expense.description}
						onChange={(e) => setExpense({ ...expense, description: e.target.value })}
						placeholder='Enter description'
					/>
				</div>
			</div>
			<ModalButtons
				buttonOne={{ label: "Save", onClick: () => onSaveHandler(), disabled: isLoading }}
				buttonTwo={{ label: "Cancel", onClick: () => onCloseHandler(), disabled: isLoading }}
			/>
		</ModalTemplate>
	);
}
