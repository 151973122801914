import React, { useState } from "react";
import { formattedDate } from "../../../utils/formattedDate";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import IncomeModal from "./IncomeModal";
import ConfirmActionModal from "../../../components/ConfirmActionModal";
import Table from "../../../components/Table";
import { useDispatch } from "react-redux";
import { deleteTransaction } from "../../../redux/actions/transactionActions";

export default function TransactionTable({ transactions }) {
	const [showEditPaymentModal, setShowEditPaymentModal] = useState(false);
	const [showConfirmDeleteTransaction, setShowConfirmDeleteTransaction] = useState(false);
	const [transactionToEdit, setTransactionToEdit] = useState(null);
	const [transactionToDelete, setTransactionToDelete] = useState(null);

	const dispatch = useDispatch();

	const editTransactionHandler = (transaction) => {
		setTransactionToEdit(transaction);
		setShowEditPaymentModal(true);
	};

	const deleteTransactionHandler = (transaction) => {
		setTransactionToDelete(transaction);
		setShowConfirmDeleteTransaction(true);
	};
	// Transaction Table
	const transactionTableColumns = [
		{
			title: "Value",
		},
		{
			title: "Date",
		},
		{
			title: "Category",
		},
		{
			title: "Actions",
		},
	];

	const transactionTableRows = transactions.map((transaction) => ({
		fields: [
			{
				value: `£${(transaction.value / 100).toFixed(2)}`,
			},
			{
				value: formattedDate(transaction.dateTransaction),
			},
			{
				value: transaction.category,
			},
			{
				value: (
					<div className='flex items-center justify-center space-x-4'>
						<button onClick={() => deleteTransactionHandler(transaction)}>
							<DeleteOutlined />
						</button>
						<button onClick={() => editTransactionHandler(transaction)}>
							<EditOutlined />
						</button>
					</div>
				),
			},
		],
	}));
	return (
		<div className='flex flex-col w-full'>
			<Table columns={transactionTableColumns} rows={transactionTableRows} />

			{showEditPaymentModal && (
				<IncomeModal
					type='EDIT'
					setShowModal={setShowEditPaymentModal}
					editTransaction={transactionToEdit}
				/>
			)}
			{showConfirmDeleteTransaction && (
				<ConfirmActionModal
					label='Confirm you wish to delete transaction?'
					onConfirm={() => {
						dispatch(deleteTransaction(transactionToDelete));
						setShowConfirmDeleteTransaction(false);
					}}
					onCancel={() => setShowConfirmDeleteTransaction(false)}
				/>
			)}
		</div>
	);
}
