import React from "react";
import NavButton from "../../../../ui/NavButton";

function NavSection({ label, navButtons }) {
  return (
    <div className='flex flex-col py-2'>
      <p className='ml-8 mb-2 text-sm text-dashBlue'>{label}</p>
      {navButtons.map((button, index) => (
        <NavButton
          key={index}
          label={button.label}
          icon={button.icon}
          onClick={button.onClick}
        />
      ))}
    </div>
  );
}

export default NavSection;
