import React, { useEffect } from "react";
import SelectField from "../../../components/ui/SelectField";
import { useDispatch, useSelector } from "react-redux";
import { checkLoaded, checkPending } from "../../../utils/checkPendingStatus";
import { GET_CLIENTS } from "../../../redux/constants/clientConstants";
import { getClients } from "../../../redux/actions/clientActions";

export default function ClientSelect({ value, onChange, hideLabel, disabled }) {
	const pendingState = useSelector((state) => state.pendingStates);
	const clients = useSelector((state) => state.clients);

	// Const Declerations
	const dispatch = useDispatch();

	const clientOptions = clients?.map((item) => {
		return {
			value: item.id,
			label: item.clientName,
		};
	});

	// If clients not in redux state calls get clients
	useEffect(() => {
		if (!checkLoaded(GET_CLIENTS, pendingState) && !checkPending(GET_CLIENTS, pendingState)) {
			dispatch(getClients());
		}
	}, [pendingState, dispatch]);

	return (
		clients && (
			<SelectField
				label='Client'
				options={clientOptions}
				value={value}
				onChange={onChange}
				placeholder='Select client..'
				hideLabel={hideLabel}
				disabled={disabled}
			/>
		)
	);
}
