import React from "react";
import { mapProjectStatusToString } from "../../../utils/mapToString";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { deleteProject } from "../../../redux/actions/projectActions";
import { formattedDate } from "../../../utils/formattedDate";

function ProjectCard({ project, editHandler, client }) {
  const dispatch = useDispatch();

  const deleteProjectHandler = () => {
    dispatch(deleteProject(project));
  };

  return (
    <div className='flex flex-col w-full lg:w-3/12 mx-12 mb-8 bg-white rounded-lg'>
      <div className='flex justify-between'>
        <div className='px-2 text-white bg-dashBlue rounded-tl-lg rounded-br-lg'>
          <p>{mapProjectStatusToString(project.status)}</p>
        </div>
        <div className='px-2 bg-slate-100 rounded-tr-lg rounded-bl-lg space-x-2'>
          <button className='mb-1 px-1' onClick={editHandler}>
            <EditOutlined />
          </button>
          <button className='mb-1 px-1' onClick={() => deleteProjectHandler()}>
            <DeleteOutlined />
          </button>
        </div>
      </div>
      <div className='flex flex-col grow justify-between'>
        <p className='font-bold text-center mb-4 p-4'>
          {client} - {project.title}
        </p>
        <div className='flex p-4'>
          <div className='flex flex-col w-6/12'>
            <p className='font-light text-left'>Quoted Price:</p>
            <p className='font-light text-left'>Source:</p>
            {project.status === "COMPLETE" && (
              <p className='font-light text-left'>Completed:</p>
            )}
          </div>
          <div className='flex flex-col'>
            <p className='font-light'>
              £{(project.quotePrice / 100).toFixed(2)}
            </p>
            <p className='font-light'>{project.source}</p>
            {project.status === "COMPLETE" && (
              <p className='font-light'>
                {formattedDate(project.dateCompleted)}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectCard;
