import React from "react";

function SelectField({ label, hideLabel, options, value, onChange, placeholder, disabled }) {
	return (
		<div className='flex flex-col justify-center mt-2 mb-4 col-span-1 grow'>
			{!hideLabel && <label className='mb-2'>{label}</label>}
			<select
				className='p-2 mt-2 h-10 w-full border rounded-lg'
				value={value}
				onChange={onChange}
				disabled={disabled}
			>
				<option disabled={true} value=''>
					{placeholder}
				</option>
				{options.map((option, index) => (
					<option key={index} value={option.value}>
						{option.label}
					</option>
				))}
			</select>
		</div>
	);
}

export default SelectField;
