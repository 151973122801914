import React, { useEffect, useState } from "react";
import Layout from "../../components/layout";
import SearchField from "../../components/ui/SearchField";
import Button from "../../components/ui/Button";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import ProjectModal from "./components/ProjectModal";
import ProjectCard from "./components/ProjectCard";
import { checkLoaded, checkPending } from "../../utils/checkPendingStatus";
import { GET_CLIENTS } from "../../redux/constants/clientConstants";
import { useDispatch, useSelector } from "react-redux";
import { getClients } from "../../redux/actions/clientActions";
import { GET_PROJECTS } from "../../redux/constants/projectConstants";
import { getProjects } from "../../redux/actions/projectActions";
import SelectField from "../../components/ui/SelectField";
import FlexRowBetween from "../../components/structural/FlexRowBetween";
import { GET_TRANSACTION } from "../../redux/constants/transactionConstants";
import { getTransactions } from "../../redux/actions/transactionActions";

function ProjectsScreen() {
  // Component State
  const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);
  const [showEditProjectModal, setShowEditProjectModal] = useState(false);
  const [editProject, setEditProject] = useState(null);
  const [statusFilter, setStatusFilter] = useState("");
  const [clientFilter, setClientFilter] = useState("");
  const [projectsToDisplay, setProjectsToDisplay] = useState([]);

  // Redux State
  const pendingState = useSelector((state) => state.pendingStates);
  const clients = useSelector((state) => state.clients);
  const projects = useSelector((state) => state.projects);

  // Const Declarations
  const dispatch = useDispatch();

  // Function Declarations
  const editProjectHandler = (project) => {
    setEditProject(project);
    setShowEditProjectModal(true);
  };

  const clientFromId = (id) => {
    const client = clients?.filter((client) => client.id === id);
    const clientName = client ? client[0].clientName : "";
    return clientName;
  };

  const filterHandler = (type, value) => {
    if (type === "STATUS") {
      setStatusFilter(value);
    } else if (type === "CLIENT") {
      setClientFilter(value);
    } else if (type === "CLEAR") {
      setClientFilter("");
      setStatusFilter("");
    }
  };

  // Effect Declerations

  // If clients not in redux state calls get clients
  useEffect(() => {
    if (
      !checkLoaded(GET_CLIENTS, pendingState) &&
      !checkPending(GET_CLIENTS, pendingState)
    ) {
      dispatch(getClients());
    }
  }, [pendingState, dispatch]);

  // If projects not in redux state calls get projects
  useEffect(() => {
    if (
      !checkLoaded(GET_PROJECTS, pendingState) &&
      !checkPending(GET_PROJECTS, pendingState)
    ) {
      dispatch(getProjects());
    }
  }, [pendingState, dispatch]);

  // If transactions not in redux state calls get projects
  useEffect(() => {
    if (
      !checkLoaded(GET_TRANSACTION, pendingState) &&
      !checkPending(GET_TRANSACTION, pendingState)
    ) {
      dispatch(getTransactions());
    }
  }, [pendingState, dispatch]);

  // Applies filtering when selections changed - needs reworking
  useEffect(() => {
    if (projects) {
      if (clientFilter === "" && statusFilter === "") {
        setProjectsToDisplay(projects);
      } else if (clientFilter === "" && statusFilter !== "") {
        const statusFilteredProjects = projects.filter(
          (project) => project.status === statusFilter
        );
        setProjectsToDisplay(statusFilteredProjects);
      } else if (clientFilter !== "" && statusFilter === "") {
        const clientFilteredProjects = projects.filter(
          (project) => project.associatedClient === clientFilter
        );
        setProjectsToDisplay(clientFilteredProjects);
      } else if (clientFilter !== "" && statusFilter !== "") {
        const statusFilteredProjects = projects.filter(
          (project) => project.status === statusFilter
        );
        const clientFilteredProjects = statusFilteredProjects.filter(
          (project) => project.associatedClient === clientFilter
        );
        setProjectsToDisplay(clientFilteredProjects);
      }
    }
  }, [clientFilter, statusFilter, projects]);

  return (
    <Layout title='Projects'>
      <FlexRowBetween>
        <SearchField placeholder='Search Projects' />
        <div className='flex w-full lg:w-5/12'>
          <Button
            label='Create Project'
            onClick={() => setShowCreateProjectModal(true)}
          />
        </div>
      </FlexRowBetween>
      {clients && (
        <div className='flex flex-col lg:flex-row items-center justify-between mt-4 lg:mt-8 lg:mx-12 bg-white px-8 rounded-lg'>
          <SelectField
            hideLabel={true}
            options={[
              { label: "Quotation", value: "QUOTE" },
              { label: "Proposal Submitted", value: "PROPOSAL" },
              { label: "Work in Progress", value: "WIP" },
              { label: "Completed", value: "COMPLETE" },
            ]}
            placeholder='Filter by status'
            value={statusFilter}
            onChange={(e) => filterHandler("STATUS", e.target.value)}
          />
          <SelectField
            hideLabel={true}
            options={clients.map((client) => ({
              label: client.clientName,
              value: client.id,
            }))}
            placeholder='Filter by client'
            value={clientFilter}
            onChange={(e) => filterHandler("CLIENT", e.target.value)}
          />
          {(clientFilter !== "" || statusFilter !== "") && (
            <button
              className='mb-2 lg:mb-0 text-dashBlue border-dashBlue border h-10 rounded-lg px-2'
              onClick={() => filterHandler("CLEAR")}
            >
              Clear Filters
            </button>
          )}
        </div>
      )}
      {checkPending(GET_PROJECTS, pendingState) && (
        <LoadingSpinner message='Loading projects' />
      )}
      {checkLoaded(GET_PROJECTS, pendingState) &&
        projectsToDisplay?.length > 0 && (
          <div className='flex flex-wrap mt-4'>
            {projectsToDisplay.map((project) => (
              <ProjectCard
                key={project.id}
                project={project}
                editHandler={() => editProjectHandler(project)}
                client={clientFromId(project.associatedClient)}
              />
            ))}
          </div>
        )}
      {checkLoaded(GET_PROJECTS, pendingState) &&
        projectsToDisplay.length === 0 && (
          <div className='flex  h-40 justify-center items-center bg-white mt-4 mx-12 rounded-lg'>
            <p className='text-2xl text-dashDarkBlue'>No projects to display</p>
          </div>
        )}
      {checkLoaded(GET_PROJECTS, pendingState) && projects.length === 0 && (
        <div className='flex h-40 justify-center items-center bg-white mt-4 mx-12 rounded-lg'>
          <p className='text-2xl text-dashDarkBlue'>No projects created yet</p>
        </div>
      )}
      {showCreateProjectModal && (
        <ProjectModal setShowModal={setShowCreateProjectModal} type='NEW' />
      )}
      {showEditProjectModal && editProject && (
        <ProjectModal
          setShowModal={setShowEditProjectModal}
          type='EDIT'
          editProject={editProject}
        />
      )}
    </Layout>
  );
}

export default ProjectsScreen;
