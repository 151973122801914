import * as t from "../constants/leadConstants";
import { authenticatedGetRequest, authenticatedPostRequest } from "../helpers";

const leadRoute = "/lead";

export const getLeads = () => async (dispatch) => {
	authenticatedGetRequest(
		dispatch,
		t.GET_LEADS,
		leadRoute,
		{
			toast: false,
		},
		{
			toast: true,
			message: "Error retrieving leads",
		}
	);
};

export const postLead = (lead) => async (dispatch) => {
	authenticatedPostRequest(
		dispatch,
		t.POST_LEAD,
		leadRoute,
		{ lead },
		{ toast: true, message: "Lead Saved" },
		{ toast: true, message: "Error saving lead" }
	);
};
