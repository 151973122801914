import React from "react";
import Layout from "../../components/layout";

function TeamScreen() {
  return (
    <Layout title='Team'>
      <p>I am team screen</p>
    </Layout>
  );
}

export default TeamScreen;
