import * as t from "../constants/statsConstants";
import { requestType } from "../helpers";

export const statsReducer = (state = null, action) => {
  switch (action.type) {
    case requestType(t.GET_STATS, "SUCCESS"):
      return action.payload;
    default:
      return state;
  }
};
