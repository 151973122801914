import React, { useState } from "react";
import Table from "../../../components/Table";
import { formattedDate } from "../../../utils/formattedDate";
import { EditOutlined } from "@ant-design/icons";
import ExpenseModal from "./ExpenseModal";

export default function ExpensesTable({ expenses }) {
	const [showEditExpenseModal, setShowEditExpenseModal] = useState(false);
	const [expenseToEdit, setExpenseToEdit] = useState(null);
	expenses = expenses.slice().sort((a, b) => new Date(b.dateExpense) - new Date(a.dateExpense));

	const editExpenseHandler = (expense) => {
		setExpenseToEdit(expense);
		setShowEditExpenseModal(true);
	};

	return (
		<div className='flex flex-wrap mt-4 rounded-t-xl overflow-clip'>
			{expenses && (
				<Table
					columns={[
						{ title: "Date" },
						{ title: "Payee" },
						{ title: "Type" },
						{ title: "Category" },
						{ title: "Value" },
						{ title: "Description" },
						{ title: "Actions" },
					]}
					rows={expenses.map((expense) => ({
						fields: [
							{ value: formattedDate(expense.dateExpense) },
							{ value: expense.payee },
							{ value: expense.type },
							{ value: expense.category },
							{ value: `£${(expense.value / 100).toFixed(2)}` },
							{ value: expense.description },
							{
								value: (
									<div className='flex items-center justify-center space-x-4'>
										<button onClick={() => editExpenseHandler(expense)}>
											<EditOutlined />
										</button>
									</div>
								),
							},
						],
					}))}
				/>
			)}
			{showEditExpenseModal && (
				<ExpenseModal
					type='EDIT'
					setShowModal={setShowEditExpenseModal}
					editExpense={expenseToEdit}
				/>
			)}
		</div>
	);
}
