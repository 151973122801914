import { CloseCircleOutlined } from "@ant-design/icons";
import React from "react";

function ModalTemplate({ children, title, onClose, hideClose }) {
	return (
		<div className='flex bg-gray-500/75 justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none '>
			<div className='relative my-6 mx-auto sm:w-[30rem] md:w-[40rem] xl:w-[48rem] max-h-screen'>
				<div className='flex flex-col border-0 rounded-lg shadow-lg relative w-full bg-white outline-none focus:outline-none'>
					<div className='flex items-center justify-between space-x-10 p-4 border-b border-solid border-gray-300 rounded-t '>
						<h3 className='text-2xl font-semibold'>{title}</h3>
						{!hideClose && (
							<button
								className='bg-transparent border-0 text-dashDarkBlue float-right'
								onClick={() => onClose()}
							>
								<CloseCircleOutlined className='text-3xl text-dashDarkBlue' />
							</button>
						)}
					</div>
					<div className='flex flex-col items-center p-4'>{children}</div>
				</div>
			</div>
		</div>
	);
}

export default ModalTemplate;
