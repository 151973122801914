import {
  CloseOutlined,
  DownCircleOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import UserMenu from "./components/UserMenu";

function Header({ title, showSidebar, setShowSidebar }) {
  const auth = useSelector((state) => state.auth);
  const [showUserMenu, setShowUserMenu] = useState(false);
  return (
    <div
      className={`flex ${
        showSidebar ? "justify-center" : "justify-between"
      } w-full bg-white h-20 items-center px-8 font-bold drop-shadow-sm`}
    >
      <button
        className='mb-1 lg:hidden'
        onClick={() => setShowSidebar(!showSidebar)}
      >
        {showSidebar ? <CloseOutlined /> : <MenuOutlined />}
      </button>
      {!showSidebar && (
        <div className='flex justify-between w-10/12 lg:w-full items-center'>
          <p className='text-dashDarkBlue text-lg lg:text-2xl'>{title}</p>
          <div className='flex flex-col relative'>
            <button
              className={`flex items-center space-x-2 px-4 py-2 ${
                showUserMenu
                  ? "border-t border-l border-r rounded-tl-lg rounded-tr-lg"
                  : "border rounded-lg"
              }`}
              onClick={() => setShowUserMenu(!showUserMenu)}
            >
              <p>
                {auth.user.firstName} {auth.user.lastName}
              </p>
              <DownCircleOutlined />
            </button>
            {showUserMenu && <UserMenu />}
          </div>
        </div>
      )}
    </div>
  );
}

export default Header;
