import { CloseCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import TextField from "../../../components/ui/TextField";
import SelectField from "../../../components/ui/SelectField";
import CheckField from "../../../components/ui/CheckField";
import NumberField from "../../../components/ui/NumberField";
import CurrencyField from "../../../components/ui/CurrencyField";
import Button from "../../../components/ui/Button";
import TransactionTable from "../../income/components/TransactionTable";
import IncomeModal from "../../income/components/IncomeModal";
import { useDispatch, useSelector } from "react-redux";
import { putClient } from "../../../redux/actions/clientActions";
import { POST_CLIENT, PUT_CLIENT } from "../../../redux/constants/clientConstants";
import { resetHandler } from "../../../redux/actions/pendingActions";
import TrackedTimeTable from "../../time/components/TrackedTimeTable";
import { formattedElapsedTime, getElapsedTime } from "../../time/utils/timeUtil";

export default function ClientFlyout({ setShow, clientToView }) {
	const pendingState = useSelector((state) => state.pendingStates);
	const transactions = useSelector((state) => state.transactions);
	const timeState = useSelector((state) => state.time);

	const [showPaymentModal, setShowPaymentModal] = useState(false);
	const [client, setClient] = useState({
		id: clientToView.id,
		clientName: clientToView.clientName,
		contactName: clientToView.contactName,
		status: clientToView.status,
		contactEmail: clientToView.contactEmail,
		retainer: clientToView.retainer,
		retainerWeeklyHours: clientToView.retainerWeeklyHours,
		retainerWeeklyFee: clientToView.retainerWeeklyFee,
	});

	// Const Declerations
	const clientTransactions = transactions.filter(
		(transaction) => transaction.associatedClient === client.id
	);

	const dispatch = useDispatch();

	const onSaveHandler = () => {
		dispatch(putClient(client));
	};

	const onCloseHandler = () => {
		setShow(false);
	};

	const unbilledHours = () => {
		const seconds = timeState?.timeEntries
			?.filter((time) => time.associatedClient === client.id && time.status === "FINISHED")
			.reduce((total, time) => total + getElapsedTime(time.startTime, time.endTime), 0);
		const hours = formattedElapsedTime(seconds);

		return hours;
	};

	// close form on success
	useEffect(() => {
		if (pendingState.PUT_CLIENT?.loaded) {
			setShow(false);
			dispatch(resetHandler(PUT_CLIENT));
		}
	});

	return (
		<div className='flex flex-col absolute top-0 right-0 min-h-screen w-6/12 bg-white shadow-xl rounded-tr-lg'>
			{/* Top Header */}
			<div className='flex items-center justify-between p-4 border-b border-solid border-gray-300 '>
				<h3 className='text-2xl font-semibold'>Client</h3>
				<button
					className='bg-transparent border-0 text-dashDarkBlue float-right'
					onClick={() => onCloseHandler()}
				>
					<CloseCircleOutlined className='text-3xl text-dashDarkBlue' />
				</button>
			</div>
			{/* /* Body */}
			<div className='flex flex-col grow p-4'>
				<div className='grid grid-cols-2 gap-4'>
					<TextField
						label='Client Name'
						value={client.clientName}
						onChange={(e) => setClient({ ...client, clientName: e.target.value })}
					/>
					<SelectField
						label='Status'
						options={[
							{ label: "Quotation", value: "QUOTE" },
							{ label: "Order Received", value: "ORDER" },
							{ label: "Active Client", value: "RETAINER" },
							{ label: "Dormant Client", value: "DORMANT" },
						]}
						value={client.status}
						onChange={(e) => setClient({ ...client, status: e.target.value })}
					/>
				</div>
				<div className='grid grid-cols-2 gap-4 mt-4'>
					<TextField
						label='Contact Name'
						value={client.contactName}
						onChange={(e) => setClient({ ...client, contactName: e.target.value })}
					/>
					<TextField
						label='Contact Email'
						value={client.contactEmail}
						onChange={(e) => setClient({ ...client, contactEmail: e.target.value })}
					/>
				</div>
				<div className='grid grid-cols-4 gap-4 mt-4'>
					<CheckField
						label='Retainer'
						value={client.retainer}
						onChange={(e) => setClient({ ...client, retainer: e.target.checked })}
					/>
					{client.retainer && (
						<NumberField
							label='Weekly Hours'
							type='number'
							value={client.retainerWeeklyHours}
							onChange={(e) => setClient({ ...client, retainerWeeklyHours: e.target.value })}
						/>
					)}
					{client.retainer && (
						<CurrencyField
							label='Weekly Fee'
							value={client.retainerWeeklyFee}
							onChange={(e) => setClient({ ...client, retainerWeeklyFee: e.target.value })}
						/>
					)}
					{client.retainer && (
						<Button label='Add Payment' onClick={() => setShowPaymentModal(true)} />
					)}
				</div>
				<h3 className='text-xl my-2 font-semibold'>Transactions</h3>
				<TransactionTable transactions={clientTransactions} />
				<h3 className='text-xl mt-8 mb-2 font-semibold'>Time Entries</h3>
				<div className='grid grid-cols-2 gap-4'>
					<TextField label='Unbilled Hours' value={unbilledHours()} disabled={true} />
				</div>
				<TrackedTimeTable
					trackedTime={timeState.timeEntries
						.filter(
							(time) =>
								time.associatedClient === client.id &&
								time.status !== "NEW" &&
								time.status !== "ACTIVE"
						)
						.sort((a, b) => new Date(b.startTime) - new Date(a.startTime))}
				/>
			</div>
			{/* Footer */}
			<div className='flex items-center justify-between p-4 border-t border-solid border-gray-300 '>
				<Button label='Save' onClick={() => onSaveHandler()} />
				<Button label='Cancel' onClick={() => onCloseHandler()} />
			</div>
			{showPaymentModal && (
				<IncomeModal
					type='NEW'
					setShowModal={setShowPaymentModal}
					category='RETAINER'
					clientId={client.id}
				/>
			)}
		</div>
	);
}
