function getActionName(actionType) {
  if (typeof actionType !== "string") {
    return null;
  }

  return actionType.split("_").slice(0, -1).join("_");
}

export const pendingReducer = (state = {}, action) => {
  const { type } = action;
  const actionName = getActionName(type);

  if (!actionName) {
    return {
      ...state,
    };
  }

  if (type === "SIGN_OUT_USER") {
    return {
      [actionName]: {
        pending: false,
        loaded: true,
      },
    };
  }

  if (type.endsWith("_REQUEST")) {
    return {
      ...state,
      [actionName]: {
        pending: true,
      },
    };
  }

  if (type.endsWith("_SUCCESS")) {
    return {
      ...state,
      [actionName]: {
        pending: false,
        loaded: true,
      },
    };
  }
  if (type.endsWith("_FAILURE")) {
    return {
      ...state,
      [actionName]: {
        pending: false,
        loaded: true,
      },
    };
  }
  if (type.endsWith("_RESET")) {
    return {
      ...state,
      [actionName]: null,
    };
  }

  return {
    ...state,
  };
};
