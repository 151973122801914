import axios from "axios";
import { axiosErrorHandler, config, expenseRoute } from "../../../app/api";

export const updateExpenseApi = async (expense, userToken) => {
	try {
		const { data } = await axios.put(expenseRoute, { expense }, config(userToken));
		return data;
	} catch (error) {
		return axiosErrorHandler(error);
	}
};
