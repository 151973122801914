import React from "react";

function PasswordField({ label, value, placeholder, onChange }) {
  return (
    <div className='flex flex-col w-full mt-2 mb-4 mx-4 grow'>
      <label className='mb-2'>{label}</label>
      <input
        className='p-2 mt-2 h-10 border rounded-lg'
        value={value}
        type='password'
        placeholder={placeholder}
        autoComplete='password'
        onChange={onChange}
      />
    </div>
  );
}

export default PasswordField;
