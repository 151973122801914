import React, { useState } from "react";
import Table from "../../../components/Table";
import { formattedElapsedTime, getElapsedTime } from "../utils/timeUtil";
import { useSelector } from "react-redux";
import { formattedDate } from "../../../utils/formattedDate";
import { ApiOutlined, FileTextOutlined, WalletOutlined } from "@ant-design/icons";
import useTime from "../hooks/useTime";
import LinkTimeTransactionModal from "./LinkTimeTransactionModal";

export default function TrackedTimeTable({ trackedTime }) {
	const [showLinkTransaction, setShowLinkTransaction] = useState(false);
	const [entryToLink, setEntryToLink] = useState(null);
	const clients = useSelector((state) => state.clients);

	const { updateTimeEntryStatus } = useTime();

	const clientFromId = (id) => {
		const client = clients?.filter((client) => client.id === id);
		const clientName = client ? client[0]?.clientName : "";
		return clientName;
	};

	const statusConverter = (status) => {
		switch (status) {
			case "FINISHED":
				return "Unbilled";

			case "INVOICED":
				return "Invoice Sent";

			case "PAID":
				return "Payment Received";

			default:
				return "Unknown";
		}
	};

	const markInvoiced = (row) => {
		updateTimeEntryStatus(row, "INVOICED");
	};

	const markPaid = (row) => {
		updateTimeEntryStatus(row, "PAID");
	};

	const linkToTransactionHandler = (row) => {
		setEntryToLink(row);
		setShowLinkTransaction(true);
	};

	return (
		<div className='flex flex-wrap mt-4 rounded-t-xl overflow-clip'>
			{trackedTime && (
				<Table
					columns={[
						{ title: "Date" },
						{ title: "Client" },
						{ title: "Description" },
						{ title: "Hours" },
						{ title: "Status" },
						{ title: "Actions" },
					]}
					rows={trackedTime.map((time) => ({
						fields: [
							{ value: formattedDate(time.startTime) },
							{ value: clientFromId(time.associatedClient) },
							{ value: time.description },
							{ value: formattedElapsedTime(getElapsedTime(time.startTime, time.endTime)) },
							{ value: statusConverter(time.status) },
							{
								value: (
									<div className='flex justify-center items-center space-x-2'>
										<button
											onClick={() => markInvoiced(time)}
											disabled={time.status !== "FINISHED"}
											className='disabled:hidden'
										>
											<FileTextOutlined title='Mark Invoiced' />
										</button>
										<button
											onClick={() => markPaid(time)}
											disabled={time.status !== "INVOICED"}
											className='disabled:hidden'
										>
											<WalletOutlined />
										</button>
										<button
											onClick={() => linkToTransactionHandler(time)}
											disabled={time.status !== "PAID" || time.associatedTransaction}
											className='disabled:hidden'
										>
											<ApiOutlined title='Link to transaction' />
										</button>
									</div>
								),
							},
						],
					}))}
				/>
			)}
			{showLinkTransaction && entryToLink && (
				<LinkTimeTransactionModal setShowModal={setShowLinkTransaction} entryToLink={entryToLink} />
			)}
		</div>
	);
}
