import React, { useEffect, useState } from "react";
import Layout from "../../components/layout";
import Button from "../../components/ui/Button";
import ClientModal from "./components/ClientModal";
import ClientCard from "./components/ClientCard";
import SearchField from "../../components/ui/SearchField";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import { useDispatch, useSelector } from "react-redux";
import { getClients } from "../../redux/actions/clientActions";
import { checkLoaded, checkPending } from "../../utils/checkPendingStatus";
import { GET_CLIENTS } from "../../redux/constants/clientConstants";
import FlexRowBetween from "../../components/structural/FlexRowBetween";
import { GET_TRANSACTION } from "../../redux/constants/transactionConstants";
import { getTransactions } from "../../redux/actions/transactionActions";
import ClientFlyout from "../../features/clients/components/ClientFlyout";

function ClientsScreen() {
	const pendingState = useSelector((state) => state.pendingStates);
	const clients = useSelector((state) => state.clients);
	const [showAddClientModal, setShowAddClientModal] = useState(false);
	const [showEditClientModal, setShowEditClientModal] = useState(false);
	const [editClient, setEditClient] = useState(null);

	const editClientHandler = (client) => {
		setEditClient(client);
		setShowEditClientModal(true);
	};

	const dispatch = useDispatch();

	useEffect(() => {
		if (!checkLoaded(GET_CLIENTS, pendingState) && !checkPending(GET_CLIENTS, pendingState)) {
			dispatch(getClients());
		}
	}, [pendingState, dispatch]);

	// If transactions not in redux state calls get projects
	useEffect(() => {
		if (
			!checkLoaded(GET_TRANSACTION, pendingState) &&
			!checkPending(GET_TRANSACTION, pendingState)
		) {
			dispatch(getTransactions());
		}
	}, [pendingState, dispatch]);

	return (
		<Layout title='Clients'>
			<FlexRowBetween>
				<SearchField placeholder='Search Clients' />
				<div className='flex w-full lg:w-5/12'>
					<Button label='Add Client' onClick={() => setShowAddClientModal(true)} />
				</div>
			</FlexRowBetween>
			{checkPending(GET_CLIENTS, pendingState) && <LoadingSpinner message='Loading clients' />}
			{checkLoaded(GET_CLIENTS, pendingState) && clients.length > 0 && (
				<div className='flex flex-wrap mt-10'>
					{clients.map((client) => (
						<ClientCard
							key={client.id}
							client={client}
							clients={clients}
							editHandler={() => editClientHandler(client)}
						/>
					))}
				</div>
			)}
			{checkLoaded(GET_CLIENTS, pendingState) && clients.length === 0 && (
				<div className='flex w-full h-40 justify-center items-center bg-white mt-10 rounded-lg'>
					<p className='text-2xl text-dashDarkBlue'>No clients added yet</p>
				</div>
			)}
			{showAddClientModal && <ClientModal setShowModal={setShowAddClientModal} type='NEW' />}
			{showEditClientModal && editClient && (
				<ClientFlyout setShow={setShowEditClientModal} clientToView={editClient} />
			)}
		</Layout>
	);
}

export default ClientsScreen;
