import * as t from "../constants/portfolioConstants";
import { requestType } from "../helpers";

export const portfolioReducer = (state = null, action) => {
  switch (action.type) {
    case requestType(t.GET_PORTFOLIO, "SUCCESS"):
      return action.payload;
    case requestType(t.GET_PORTFOLIO, "FAILURE"):
      return [];
    case requestType(t.POST_PORTFOLIO, "SUCCESS"):
      return [action.payload, ...state];
    case requestType(t.PUT_PORTFOLIO, "SUCCESS"):
      const filterUpdatedResource = state.filter(
        (resource) => resource.id !== action.payload.id
      );
      return [action.payload, ...filterUpdatedResource];
    case requestType(t.DELETE_PORTFOLIO, "SUCCESS"):
      const filterDeletedResource = state.filter(
        (resource) => resource.id !== action.payload.portfolio.id
      );
      return filterDeletedResource;
    default:
      return state;
  }
};
