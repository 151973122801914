import React, { useEffect, useState } from "react";
import ClientSelect from "../../clients/components/ClientSelect";
import Button from "../../../components/ui/Button";
import { formattedElapsedTime } from "../utils/timeUtil";
import useTime from "../hooks/useTime";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

export default function TimeTracker() {
	const [timeEntry, setTimeEntry] = useState({
		id: "",
		associatedClient: "",
		description: "",
		status: "NEW",
		startTime: null,
		endTime: null,
	});

	const timeEntries = useSelector((state) => state.time.timeEntries);

	const [elapsedTime, setElapsedTime] = useState(0);

	const { createTimeEntry, stopTimeEntry } = useTime();

	const startTimerHandler = async () => {
		if (timeEntry.associatedClient === "") {
			toast.warning("Please select a client to start timer");
			return;
		}
		await createTimeEntry(timeEntry, setTimeEntry);
	};

	const stopTimerHandler = async () => {
		if (timeEntry.associatedClient === "") {
			toast.warning("Please select a client to end timer");
			return;
		}
		await stopTimeEntry(timeEntry, setTimeEntry);
	};

	const clearTimerHandler = async () => {
		setTimeEntry({
			id: "",
			associatedClient: "",
			description: "",
			status: "NEW",
			startTime: null,
			endTime: null,
		});
	};

	// use effect for timer
	useEffect(() => {
		if (timeEntry.status === "NEW") {
			setElapsedTime(0);
		} else if (timeEntry.status === "FINISHED") {
			const currentTime = new Date(timeEntry.endTime);
			const startTime = new Date(timeEntry.startTime);
			const elapsedSeconds = Math.floor((currentTime - startTime) / 1000);
			setElapsedTime(elapsedSeconds);
		} else {
			const intervalId = setInterval(() => {
				const currentTime = new Date();
				const startTime = new Date(timeEntry.startTime);
				const elapsedSeconds = Math.floor((currentTime - startTime) / 1000);
				setElapsedTime(elapsedSeconds);
			}, 1000);

			return () => {
				clearInterval(intervalId);
			};
		}
	}, [timeEntry.status, timeEntry.startTime, timeEntry.endTime]);

	// use effect to check for active timers and set to component state
	useEffect(() => {
		if (
			timeEntries?.length > 0 &&
			timeEntries?.filter((entry) => entry.status === "ACTIVE").length > 0 &&
			timeEntry.status === "NEW"
		) {
			const activeEntry = timeEntries?.filter((entry) => entry.status === "ACTIVE")[0];
			setTimeEntry({
				id: activeEntry.id,
				associatedClient: activeEntry.associatedClient,
				description: activeEntry.description,
				status: activeEntry.status,
				startTime: activeEntry.startTime,
				endTime: activeEntry.endTime,
			});
		}
	}, [setTimeEntry, timeEntries, timeEntry.status]);

	return (
		<div className='flex items-center bg-white px-5 py-2 rounded-md'>
			<input
				className='p-2 my-4 grow h-10 border rounded-lg'
				value={timeEntry.description}
				type='text'
				placeholder='Task Description'
				onChange={(e) => setTimeEntry({ ...timeEntry, description: e.target.value })}
			/>
			<ClientSelect
				value={timeEntry.associatedClient}
				onChange={(e) => setTimeEntry({ ...timeEntry, associatedClient: e.target.value })}
				hideLabel={true}
			/>
			<p className='text-2xl w-40 text-center'>{formattedElapsedTime(elapsedTime)}</p>
			{timeEntry.status === "NEW" && <Button label='Start' onClick={() => startTimerHandler()} />}
			{timeEntry.status === "ACTIVE" && <Button label='Stop' onClick={() => stopTimerHandler()} />}
			{timeEntry.status === "FINISHED" && (
				<Button label='New' onClick={() => clearTimerHandler()} />
			)}
		</div>
	);
}
