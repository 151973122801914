import React, { useState } from "react";
import Button from "../../components/ui/Button";
import TextField from "../../components/ui/TextField";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { createNewUser } from "../../redux/actions/authActions";
import PasswordField from "../../components/ui/PasswordField";

function RegisterScreen() {
  const [newUser, setNewUser] = useState({
    companyName: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    setPassword: "",
  });

  const dispatch = useDispatch();

  const registrationHandler = () => {
    if (newUser.companyName.trim() === "") {
      toast.warning(
        "Please provide company name. If you are a sole trader please just enter your name"
      );
      return;
    }
    if (newUser.firstName.trim() === "") {
      toast.warning("Please provide first name");
      return;
    }
    if (newUser.lastName.trim() === "") {
      toast.warning("Please provide last name");
      return;
    }
    if (newUser.email.trim() === "") {
      toast.warning("Please provide email address");
      return;
    }
    if (newUser.password.trim() === "") {
      toast.warning("Please provide password");
      return;
    }
    dispatch(createNewUser(newUser));
  };

  return (
    <div className='flex flex-col min-h-screen items-center justify-center px-4'>
      <div className='flex flex-col w-full md:w-3/12'>
        <TextField
          label='Company Name'
          type='text'
          value={newUser.companyName}
          onChange={(e) =>
            setNewUser({ ...newUser, companyName: e.target.value })
          }
        />
        <TextField
          label='First Name'
          type='text'
          value={newUser.firstName}
          onChange={(e) =>
            setNewUser({ ...newUser, firstName: e.target.value })
          }
        />
        <TextField
          label='Last Name'
          type='text'
          value={newUser.lastName}
          onChange={(e) => setNewUser({ ...newUser, lastName: e.target.value })}
        />
        <TextField
          label='Email Address'
          type='email'
          value={newUser.email}
          onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
        />
        <PasswordField
          label='Password'
          value={newUser.password}
          onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
        />
        <Button label='Join' onClick={() => registrationHandler()} />
      </div>
      <p className='mt-4'>
        Already got an account?{" "}
        <a href='/' className='text-blue-500 underline'>
          Sign In
        </a>
      </p>
    </div>
  );
}

export default RegisterScreen;
