import axios from "axios";
import { firebaseAuth } from "../firebase";
import { toast } from "react-toastify";

export const requestType = (request, type) => {
  switch (type) {
    case "REQUEST":
      return `${request}_REQUEST`;
    case "SUCCESS":
      return `${request}_SUCCESS`;
    case "FAILURE":
      return `${request}_FAILURE`;
    case "RESET":
      return `${request}_RESET`;
    default:
      return "ERROR";
  }
};

export const authenticatedGetRequest = async (
  dispatch,
  type,
  route,
  success,
  failure
) => {
  dispatch({ type: requestType(type, "REQUEST") });
  await firebaseAuth.currentUser
    .getIdToken(true)
    .then(async (idToken) => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND}${route}`,
          {
            headers: {
              Authorization: "Bearer " + idToken,
            },
          }
        );
        if (success.toast) {
          toast.success(success.message);
        }
        dispatch({ type: requestType(type, "SUCCESS"), payload: data });
      } catch (error) {
        console.log(error);
        if (failure.toast) {
          toast.error(failure.message);
        }
        dispatch({ type: requestType(type, "FAILURE") });
      }
    })
    .catch((error) => {
      toast.error("Authentication Error");
      console.log(error);
      dispatch({ type: requestType(type, "FAILURE") });
    });
};

export const authenticatedPostRequest = async (
  dispatch,
  type,
  route,
  body,
  success,
  failure
) => {
  dispatch({ type: requestType(type, "REQUEST") });
  await firebaseAuth.currentUser
    .getIdToken(true)
    .then(async (idToken) => {
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_BACKEND}${route}`,
          body,
          {
            headers: {
              Authorization: "Bearer " + idToken,
            },
          }
        );
        if (success.toast) {
          toast.success(success.message);
        }
        dispatch({ type: requestType(type, "SUCCESS"), payload: data });
      } catch (error) {
        console.log(error);
        if (failure.toast) {
          toast.error(failure.message);
        }
        dispatch({ type: requestType(type, "FAILURE") });
      }
    })
    .catch((error) => {
      toast.error("Authentication Error");
      console.log(error);
      dispatch({ type: requestType(type, "FAILURE") });
    });
};

export const authenticatedPutRequest = async (
  dispatch,
  type,
  route,
  body,
  success,
  failure
) => {
  dispatch({ type: requestType(type, "REQUEST") });
  await firebaseAuth.currentUser
    .getIdToken(true)
    .then(async (idToken) => {
      try {
        const { data } = await axios.put(
          `${process.env.REACT_APP_BACKEND}${route}`,
          body,
          {
            headers: {
              Authorization: "Bearer " + idToken,
            },
          }
        );
        if (success.toast) {
          toast.success(success.message);
        }
        dispatch({ type: requestType(type, "SUCCESS"), payload: data });
      } catch (error) {
        console.log(error);
        if (failure.toast) {
          toast.error(failure.message);
        }
        dispatch({ type: requestType(type, "FAILURE") });
      }
    })
    .catch((error) => {
      toast.error("Authentication Error");
      console.log(error);
      dispatch({ type: requestType(type, "FAILURE") });
    });
};

export const authenticatedDeleteRequest = async (
  dispatch,
  type,
  route,
  body,
  success,
  failure
) => {
  dispatch({ type: requestType(type, "REQUEST") });
  await firebaseAuth.currentUser
    .getIdToken(true)
    .then(async (idToken) => {
      try {
        const { data } = await axios.delete(
          `${process.env.REACT_APP_BACKEND}${route}`,
          {
            headers: { Authorization: "Bearer " + idToken },
            data: body,
          }
        );
        if (data === 1) {
          if (success.toast) {
            toast.success(success.message);
          }
          dispatch({ type: requestType(type, "SUCCESS"), payload: body });
        } else {
          toast.warning(
            "Issue with deletion please refresh application and check if successful"
          );
        }
      } catch (error) {
        console.log(error);
        if (failure.toast) {
          toast.error(failure.message);
        }
        dispatch({ type: requestType(type, "FAILURE") });
      }
    })
    .catch((error) => {
      toast.error("Authentication Error");
      console.log(error);
      dispatch({ type: requestType(type, "FAILURE") });
    });
};
