import { configureStore } from "@reduxjs/toolkit";
import { authReducer } from "./reducers/authReducer";
import { clientReducer } from "./reducers/clientReducer";
import expenseReducer from "../features/expenses/store/expense.slice";
import { pendingReducer } from "./reducers/pendingReducer";
import { portfolioReducer } from "./reducers/portfolioReducer";
import { projectReducer } from "./reducers/projectReducer";
import { statsReducer } from "./reducers/statsReducer";
import subscriptionReducer from "../features/subscriptions/store/subscription.slice";
import timeReducer from "../features/time/store/time.slice";
import { transactionReducer } from "./reducers/transactionReducer";
import { leadReducer } from "./reducers/leadReducer";

export const store = configureStore({
	reducer: {
		auth: authReducer,
		clients: clientReducer,
		expenses: expenseReducer,
		leads: leadReducer,
		pendingStates: pendingReducer,
		portfolio: portfolioReducer,
		projects: projectReducer,
		stats: statsReducer,
		subscriptions: subscriptionReducer,
		time: timeReducer,
		transactions: transactionReducer,
	},
});
