import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Colors, Tooltip } from "chart.js";

ChartJS.register(ArcElement, Colors, Tooltip);

function PieChart({ label, data }) {
  return (
    <div className='flex flex-col w-full lg:w-5/12 items-center bg-white mt-8 lg:m-8 p-8 rounded-lg hover:shadow-lg'>
      <p className='text-xl font-bold mb-8'>{label}</p>
      <Pie data={data} options={{ cutout: 80 }} />
    </div>
  );
}

export default PieChart;
