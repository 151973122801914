import React from "react";
import CopyClipboardButton from "../../../components/ui/CopyClipboardButton";
import ExternalLink from "../../../components/ui/ExternalLink";
import ResourceRowSection from "./ResourceRowSection";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

function ResourceRow({ resource, editHandler, client }) {
  return (
    <div className='flex flex-col lg:flex-row bg-white mb-4 px-4 py-2 rounded-lg w-full text-xs'>
      <div className='flex flex-col lg:flex-row lg:grow lg:flex-wrap border-r'>
        <ResourceRowSection label='Name' value={resource.name} />
        <ResourceRowSection label='Client' value={client} />
        {resource.services.frontend && (
          <>
            <ResourceRowSection
              label='Frontend Host'
              value={resource.services.frontendHosting}
            />
            <div className='flex flex-col w-[180px] items-center mb-2 lg:mb-0 px-4 lg:border-r'>
              <p className='underline mb-1'>Frontend URL</p>
              <div className='flex space-x-2'>
                <CopyClipboardButton
                  textToCopy={resource.services.frontendProductionURL}
                  label='Copy'
                />
                <span className='border-r'></span>
                <ExternalLink
                  label='Visit'
                  to={resource.services.frontendProductionURL}
                />
              </div>
            </div>
          </>
        )}
        {resource.services.server && (
          <>
            <ResourceRowSection
              label='Server Host'
              value={resource.services.serverHosting}
            />
            <div className='flex flex-col w-[180px] items-center mb-2 lg:mb-0 px-4 lg:border-r'>
              <p className='underline mb-1'>Server URL</p>
              <div className='flex space-x-2'>
                <CopyClipboardButton
                  textToCopy={resource.services.serverUrl}
                  label='Copy'
                />
                <span className='border-r'></span>
                <ExternalLink label='Visit' to={resource.services.serverUrl} />
              </div>
            </div>
            <div className='flex flex-col w-[180px] items-center mb-2 lg:mb-0 px-4 lg:border-r'>
              <p className='underline mb-1'>Server IP</p>
              <div className='flex space-x-2'>
                <CopyClipboardButton
                  textToCopy={resource.services.serverIp}
                  label='Copy'
                />
              </div>
            </div>
          </>
        )}
        {resource.services.database && (
          <ResourceRowSection
            label='Database Host'
            value={resource.services.databaseHosting}
          />
        )}
      </div>
      <div className='flex my-4 lg:my-0 border-t lg:border-t-0 px-4 items-center justify-center lg:justify-end space-x-4 text-2xl'>
        <button className='mb-1 px-1' onClick={editHandler}>
          <EditOutlined />
        </button>
        <button className='mb-1 px-1'>
          <DeleteOutlined />
        </button>
      </div>
    </div>
  );
}

export default ResourceRow;
