import { SearchOutlined } from "@ant-design/icons";
import React from "react";

function SearchField({ placeholder, value, setValue }) {
  return (
    <div className='flex items-center justify-between mb-4 lg:mb-0 w-full lg:w-5/12 bg-white px-8 rounded-lg'>
      <input
        placeholder={placeholder}
        className='w-full py-2 outline-none'
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <SearchOutlined />
    </div>
  );
}

export default SearchField;
