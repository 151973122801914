import axios from "axios";
import { axiosErrorHandler, config, expenseRoute } from "../../../app/api";

export const getExpensesApi = async (userToken) => {
	try {
		const { data } = await axios.get(expenseRoute, config(userToken));
		return data;
	} catch (error) {
		return axiosErrorHandler(error);
	}
};
