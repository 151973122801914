import * as t from "../constants/transactionConstants";
import {
  authenticatedDeleteRequest,
  authenticatedGetRequest,
  authenticatedPostRequest,
  authenticatedPutRequest,
} from "../helpers";

const transactionRoute = "/transaction";

export const getTransactions = () => async (dispatch) => {
  authenticatedGetRequest(
    dispatch,
    t.GET_TRANSACTION,
    transactionRoute,
    {
      toast: false,
    },
    {
      toast: true,
      message: "Error retrieving resources",
    }
  );
};

export const postTransaction = (transaction) => async (dispatch) => {
  authenticatedPostRequest(
    dispatch,
    t.POST_TRANSACTION,
    transactionRoute,
    { transaction },
    { toast: true, message: "Transaction added" },
    { toast: true, message: "Error adding transaction" }
  );
};

export const putTransaction = (transaction) => async (dispatch) => {
  authenticatedPutRequest(
    dispatch,
    t.PUT_TRANSACTION,
    transactionRoute,
    { transaction },
    { toast: true, message: "Transaction updated" },
    { toast: true, message: "Error updating transaction" }
  );
};

export const deleteTransaction = (transaction) => async (dispatch) => {
  authenticatedDeleteRequest(
    dispatch,
    t.DELETE_TRANSACTION,
    transactionRoute,
    { transaction },
    { toast: true, message: "Transaction deleted" },
    { toast: true, message: "Error deleting transaction" }
  );
};
