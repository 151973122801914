import React from "react";

function CheckField({ label, value, onChange }) {
  return (
    <div className='flex flex-col items-center w-[180px] mt-2 mb-4 mx-4'>
      <label className='mb-2 text-center'>{label}</label>
      <input
        className='mt-2 h-10'
        type='checkbox'
        checked={value}
        onChange={onChange}
      />
    </div>
  );
}

export default CheckField;
