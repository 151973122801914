import React, { useEffect, useState } from "react";
import ModalTemplate from "../../../components/modals/ModalTemplate";
import FlexRowBetween from "../../../components/structural/FlexRowBetween";
import TextField from "../../../components/ui/TextField";
import ModalButtons from "../../../components/modals/ModalButtons";
import { POST_LEAD } from "../../../redux/constants/leadConstants";
import { useDispatch, useSelector } from "react-redux";
import { postLead } from "../../../redux/actions/leadActions";
import { resetHandler } from "../../../redux/actions/pendingActions";

export default function LeadModal({ setShowModal, type, editLead }) {
	const [lead, setLead] = useState({
		name: "",
		company: "",
		email: "",
	});

	// Redux State
	const pendingState = useSelector((state) => state.pendingStates);

	const dispatch = useDispatch();

	const onCloseHandler = () => {
		setShowModal(false);
	};

	const onSaveHandler = () => {
		if (type === "NEW") {
			dispatch(postLead(lead));
		}
	};

	// close form on success
	useEffect(() => {
		if (type === "NEW" && pendingState.POST_LEAD?.loaded) {
			setShowModal(false);
			dispatch(resetHandler(POST_LEAD));
		}
	});

	return (
		<ModalTemplate
			title={type === "NEW" ? "Add Lead" : "Edit Lead"}
			onClose={() => onCloseHandler()}
			disabled={false}
		>
			<FlexRowBetween width='w-full'>
				<TextField
					label='Lead Name'
					value={lead.name}
					onChange={(e) => setLead({ ...lead, name: e.target.value })}
				/>
				<TextField
					label='Company'
					value={lead.company}
					onChange={(e) => setLead({ ...lead, company: e.target.value })}
				/>
			</FlexRowBetween>
			<FlexRowBetween width='w-full'>
				<TextField
					label='Email'
					value={lead.email}
					onChange={(e) => setLead({ ...lead, email: e.target.value })}
				/>
			</FlexRowBetween>
			<ModalButtons
				buttonOne={{
					label: "Save",
					onClick: () => onSaveHandler(),
				}}
				buttonTwo={{
					label: "Cancel",
					onClick: () => onCloseHandler(),
				}}
			/>
		</ModalTemplate>
	);
}
