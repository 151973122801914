import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	subscriptions: [],
	loaded: false,
};

export const subscriptionsSlice = createSlice({
	name: "subscription",
	initialState,
	reducers: {
		setSubscriptions: (state, action) => {
			state.subscriptions = action.payload;
			state.loaded = true;
		},

		updateSubscriptions: (state, action) => {
			const newState = state.subscriptions.filter(
				(subscription) => subscription.id !== action.payload.id
			);
			state.subscriptions = [...newState, action.payload];
			state.loaded = true;
		},
	},
});

export const { setSubscriptions, updateSubscriptions } = subscriptionsSlice.actions;
export default subscriptionsSlice.reducer;
