import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	expenses: [],
	loaded: false,
};

export const expensesSlice = createSlice({
	name: "expense",
	initialState,
	reducers: {
		setExpenses: (state, action) => {
			state.expenses = action.payload;
			state.loaded = true;
		},
		updateExpenses: (state, action) => {
			const newState = state.expenses.filter((expense) => expense.id !== action.payload.id);
			state.expenses = [...newState, action.payload];
			state.loaded = true;
		},
	},
});

export const { setExpenses, updateExpenses } = expensesSlice.actions;
export default expensesSlice.reducer;
