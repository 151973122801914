import axios from "axios";
import {
  createUserWithEmailAndPassword,
  deleteUser,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { firebaseAuth } from "../../firebase";
import * as t from "../constants/authConstants";
import { mapAuthCodeToMessage } from "../../utils/firebaseAuthErrorHandling";
import { toast } from "react-toastify";
import { authenticatedPutRequest, requestType } from "../helpers";

const authRoute = `${process.env.REACT_APP_BACKEND}/auth`;

// Creates a firebase user for authentication and then creates the user on the backend, returned user is then added to redux state.
export const createNewUser = (newUser) => async (dispatch) => {
  dispatch({ type: requestType(t.SIGN_IN_USER, "REQUEST") });
  // Create firebase user
  createUserWithEmailAndPassword(firebaseAuth, newUser.email, newUser.password)
    .then(async (userCredential) => {
      const userToken = userCredential.user.accessToken;
      // Send user to backend and register
      try {
        const { data } = await axios.post(
          authRoute,
          {
            newUser,
          },
          {
            headers: {
              Authorization: "Bearer " + userToken,
            },
          }
        );
        // Dispatch user to redux state
        dispatch({
          type: requestType(t.SIGN_IN_USER, "SUCCESS"),
          payload: data,
        });
      } catch (error) {
        console.log("Error creating new user:", error);
        toast.error("Error creating account");
        deleteUser(userCredential.user);
        dispatch({ type: requestType(t.SIGN_IN_USER, "FAILURE") });
      }
    })
    .catch((error) => {
      const message = mapAuthCodeToMessage(error.code);
      console.log(message === "" ? error.code : message);
      toast.error(message === "" ? error.code : message);
      dispatch({ type: requestType(t.SIGN_IN_USER, "FAILURE") });
    });
};

// Sign in user with email and password to firebase and then retrieve user from backend and pass to redux state.
export const signInUserEmailAndPassword =
  (email, password) => async (dispatch) => {
    dispatch({ type: requestType(t.SIGN_IN_USER, "REQUEST") });
    // Sign in firebase user
    signInWithEmailAndPassword(firebaseAuth, email, password)
      .then(async (userCredential) => {
        var userToken = userCredential.user.accessToken;
        // Retrieve user from backend
        try {
          const { data } = await axios.get(authRoute, {
            headers: {
              Authorization: "Bearer " + userToken,
            },
          });
          // Dispatch user to redux state
          dispatch({
            type: requestType(t.SIGN_IN_USER, "SUCCESS"),
            payload: data,
          });
        } catch (error) {
          console.log(error.response.status);
          if (error.response.status === 404) {
            toast.error("No user found");
          } else {
            toast.error("Sign In Failed");
          }
          dispatch({ type: requestType(t.SIGN_IN_USER, "FAILURE") });
        }
      })
      .catch((error) => {
        const message = mapAuthCodeToMessage(error.code);
        console.log(message === "" ? error.code : message);
        toast.error(message === "" ? error.code : message);
        dispatch({ type: requestType(t.SIGN_IN_USER, "FAILURE") });
      });
  };

// Used where firebase authentication already present to retrieve user from backend and pass to redux state
export const signInUserToken = (token) => async (dispatch) => {
  // Retrieve user from backend
  try {
    const { data } = await axios.get(authRoute, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    // Dispatch user to redux state
    dispatch({
      type: requestType(t.SIGN_IN_USER, "SUCCESS"),
      payload: data,
    });
  } catch (error) {
    console.log(error);
    // Clears firebase authentication if error retrieving user from backend
    dispatch(signOutUser());
  }
};

// Sign out user from firebase and clear user state from redux
export const signOutUser = () => (dispatch) => {
  // Sign out user from firebase
  signOut(firebaseAuth)
    .then(() => {
      // Clear redux state
      dispatch({
        type: t.SIGN_OUT_USER,
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

// Update user retrieves firebase authentication token and updates user on backend, then passes new state to redux
export const updateUser = (editUser) => (dispatch) => {
  authenticatedPutRequest(
    dispatch,
    t.PUT_USER,
    "/auth",
    { editUser },
    { toast: true, message: "Profile Updated" },
    { toast: true, message: "Error updating profile" }
  );
};

// Used to update redux state when firebase authentication is not already present on application load
export const markNotAuthenticated = () => (dispatch) => {
  dispatch({
    type: t.NOT_AUTHENTICATED,
  });
};
