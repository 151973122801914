import axios from "axios";
import { axiosErrorHandler, config, timeRoute } from "../../../app/api";

export const createTimeEntryApi = async (timeEntry, userToken) => {
	try {
		const { data } = await axios.post(timeRoute, { timeEntry }, config(userToken));
		return data;
	} catch (error) {
		return axiosErrorHandler(error);
	}
};
