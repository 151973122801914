import { useState } from "react";
import { firebaseAuth } from "../../../firebase";
import { getExpensesApi } from "../api/get.expense";
import { useDispatch } from "react-redux";
import { setExpenses, updateExpenses } from "../store/expense.slice";
import { toast } from "react-toastify";
import { createExpenseApi } from "../api/post.expense";
import { updateExpenseApi } from "../api/put.expense";

export default function useExpenses() {
	const [isLoading, setIsLoading] = useState(false);
	const [actionSuccessful, setActionSuccessful] = useState(false);

	const dispatch = useDispatch();

	const getExpenses = async () => {
		setIsLoading(true);
		firebaseAuth.currentUser?.getIdToken(true).then(async (idToken) => {
			try {
				const expenses = await getExpensesApi(idToken);
				dispatch(setExpenses(expenses));
			} catch (error) {
				console.log(error);
				toast.error("Error retrieving expenses");
			} finally {
				setIsLoading(false);
			}
		});
	};

	const createExpense = async (expense) => {
		setIsLoading(true);
		firebaseAuth.currentUser?.getIdToken(true).then(async (idToken) => {
			try {
				const newExpense = await createExpenseApi(expense, idToken);
				dispatch(updateExpenses(newExpense));
				setActionSuccessful(true);
				toast.success("Expense Created");
			} catch (error) {
				console.log(error);
				toast.error("Error creating expense");
			} finally {
				setIsLoading(false);
			}
		});
	};

	const updateExpense = async (expense) => {
		setIsLoading(true);
		firebaseAuth.currentUser?.getIdToken(true).then(async (idToken) => {
			try {
				const updatedExpense = await updateExpenseApi(expense, idToken);
				dispatch(updateExpenses(updatedExpense));
				setActionSuccessful(true);
				toast.success("Expense Updated");
			} catch (error) {
				console.log(error);
				toast.error("Error updating expense");
			} finally {
				setIsLoading(false);
			}
		});
	};

	return { isLoading, actionSuccessful, getExpenses, createExpense, updateExpense };
}
