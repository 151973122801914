import React from "react";

function SingleFigureStat({ label, stat }) {
  return (
    <div className='flex flex-col lg:w-[180px] bg-white mx-8 px-4 py-2 rounded-lg text-center hover:shadow-lg'>
      <p className='font-bold mb-4'>{label}</p>
      <p className='mb-4'>{stat}</p>
    </div>
  );
}

export default SingleFigureStat;
