export const mapAuthCodeToMessage = (authCode) => {
  switch (authCode) {
    case "auth/invalid-password":
      return "Password provided is incorrect";

    case "auth/invalid-email":
      return "Email provided is invalid";

    case "auth/email-already-in-use":
      return "Email provided is already in use";

    case "auth/missing-email":
      return "No email address provided";

    case "auth/user-not-found":
      return "User not found";

    case "auth/wrong-password":
      return "Incorrect email/password";

    case "auth/weak-password":
      return "Password too weak";
    default:
      return "";
  }
};
