import React, { useEffect, useState } from "react";
import ModalTemplate from "../../../components/modals/ModalTemplate";
import TextField from "../../../components/ui/TextField";
import SelectField from "../../../components/ui/SelectField";
import { useDispatch, useSelector } from "react-redux";
import { postClient, putClient } from "../../../redux/actions/clientActions";
import { resetHandler } from "../../../redux/actions/pendingActions";
import { PUT_CLIENT } from "../../../redux/constants/clientConstants";
import { POST_CLIENT } from "../../../redux/constants/clientConstants";
import ModalButtons from "../../../components/modals/ModalButtons";
import { checkPending } from "../../../utils/checkPendingStatus";
import FlexRowBetween from "../../../components/structural/FlexRowBetween";
import CheckField from "../../../components/ui/CheckField";
import CurrencyField from "../../../components/ui/CurrencyField";
import NumberField from "../../../components/ui/NumberField";
import Button from "../../../components/ui/Button";
import { formattedDate } from "../../../utils/formattedDate";
import Table from "../../../components/Table";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { deleteTransaction } from "../../../redux/actions/transactionActions";
import ConfirmActionModal from "../../../components/ConfirmActionModal";
import IncomeModal from "../../../features/income/components/IncomeModal";

function ClientModal({ setShowModal, type, editClient }) {
	const [showPaymentModal, setShowPaymentModal] = useState(false);
	const [showEditPaymentModal, setShowEditPaymentModal] = useState(false);
	const [showConfirmDeleteTransaction, setShowConfirmDeleteTransaction] = useState(false);
	const [transactionToEdit, setTransactionToEdit] = useState(null);
	const [transactionToDelete, setTransactionToDelete] = useState(null);
	const [client, setClient] = useState({
		id: "",
		clientName: "",
		contactName: "",
		status: "QUOTE",
		contactEmail: "",
		retainer: false,
		retainerWeeklyHours: 0,
		retainerWeeklyFee: 0,
	});

	// Redux State
	const pendingState = useSelector((state) => state.pendingStates);

	const dispatch = useDispatch();

	// Function Declerations
	const onCloseHandler = () => {
		setShowModal(false);
	};

	const onSaveHandler = () => {
		if (type === "NEW") {
			dispatch(postClient(client));
		} else if (type === "EDIT") {
			dispatch(putClient(client));
		}
	};

	const editTransactionHandler = (transaction) => {
		setTransactionToEdit(transaction);
		setShowEditPaymentModal(true);
	};

	const deleteTransactionHandler = (transaction) => {
		setTransactionToDelete(transaction);
		setShowConfirmDeleteTransaction(true);
	};

	// close form on success
	useEffect(() => {
		if (type === "NEW" && pendingState.POST_CLIENT?.loaded) {
			setShowModal(false);
			dispatch(resetHandler(POST_CLIENT));
		} else if (type === "EDIT" && pendingState.PUT_CLIENT?.loaded) {
			setShowModal(false);
			dispatch(resetHandler(PUT_CLIENT));
		}
	});

	return (
		<ModalTemplate
			title={type === "NEW" ? "Add Client" : "Edit Client"}
			onClose={() => onCloseHandler()}
			disabled={pendingState.POST_CLIENT?.pending || pendingState.PUT_CLIENT?.pending}
		>
			<FlexRowBetween width='w-full'>
				<TextField
					label='Client Name'
					value={client.clientName}
					onChange={(e) => setClient({ ...client, clientName: e.target.value })}
				/>
				<SelectField
					label='Status'
					options={[
						{ label: "Quotation", value: "QUOTE" },
						{ label: "Order Received", value: "ORDER" },
						{ label: "Active Client", value: "RETAINER" },
						{ label: "Dormant Client", value: "DORMANT" },
					]}
					value={client.status}
					onChange={(e) => setClient({ ...client, status: e.target.value })}
				/>
			</FlexRowBetween>
			<FlexRowBetween width='w-full'>
				<TextField
					label='Contact Name'
					value={client.contactName}
					onChange={(e) => setClient({ ...client, contactName: e.target.value })}
				/>
				<TextField
					label='Contact Email'
					value={client.contactEmail}
					onChange={(e) => setClient({ ...client, contactEmail: e.target.value })}
				/>
			</FlexRowBetween>
			<FlexRowBetween width='w-full'>
				<CheckField
					label='Retainer'
					value={client.retainer}
					onChange={(e) => setClient({ ...client, retainer: e.target.checked })}
				/>
				{client.retainer && (
					<NumberField
						label='Weekly Hours'
						type='number'
						value={client.retainerWeeklyHours}
						onChange={(e) => setClient({ ...client, retainerWeeklyHours: e.target.value })}
					/>
				)}
				{client.retainer && (
					<CurrencyField
						label='Weekly Fee'
						value={client.retainerWeeklyFee}
						onChange={(e) => setClient({ ...client, retainerWeeklyFee: e.target.value })}
					/>
				)}
			</FlexRowBetween>

			<ModalButtons
				buttonOne={{
					label: "Save",
					onClick: () => onSaveHandler(),
					disabled:
						checkPending(POST_CLIENT, pendingState) || checkPending(PUT_CLIENT, pendingState),
				}}
				buttonTwo={{
					label: "Cancel",
					onClick: () => onCloseHandler(),
					disabled:
						checkPending(POST_CLIENT, pendingState) || checkPending(PUT_CLIENT, pendingState),
				}}
			/>
			{showPaymentModal && (
				<IncomeModal
					type='NEW'
					setShowModal={setShowPaymentModal}
					category='RETAINER'
					clientId={client.id}
				/>
			)}
		</ModalTemplate>
	);
}

export default ClientModal;
