import React from "react";
import Layout from "../../components/layout";

function OrganisationScreen() {
  return (
    <Layout title='Organisation'>
      <p>I am organisation screen</p>
    </Layout>
  );
}

export default OrganisationScreen;
