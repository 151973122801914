import React from "react";
import ModalTemplate from "./modals/ModalTemplate";
import ModalButtons from "./modals/ModalButtons";

function ConfirmActionModal({ label, onConfirm, onCancel }) {
  return (
    <ModalTemplate title={label} hideClose>
      <ModalButtons
        buttonOne={{ label: "Confirm", onClick: onConfirm }}
        buttonTwo={{ label: "Cancel", onClick: onCancel }}
      />
    </ModalTemplate>
  );
}

export default ConfirmActionModal;
