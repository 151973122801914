import * as t from "../constants/leadConstants";
import { requestType } from "../helpers";

export const leadReducer = (state = null, action) => {
	switch (action.type) {
		case requestType(t.GET_LEADS, "SUCCESS"):
			return action.payload;
		case requestType(t.GET_LEADS, "FAILURE"):
			return [];
		case requestType(t.POST_LEAD, "SUCCESS"):
			return [...state, action.payload];
		default:
			return state;
	}
};
