import React, { useState } from "react";
import Sidebar from "./components/sidebar/Sidebar";
import Header from "./components/header/Header";

function Layout({ children, title }) {
  const [showSidebar, setShowSidebar] = useState(false);
  return (
    <div className='flex w-full min-h-screen'>
      <Sidebar desktop={true} />
      {showSidebar && <Sidebar desktop={false} />}
      <div className='flex flex-col w-full'>
        <Header
          title={title}
          showSidebar={showSidebar}
          setShowSidebar={setShowSidebar}
        />
        {!showSidebar && (
          <div className='flex flex-col w-full grow bg-dashBackground text-dashDarkBlue py-4 px-4 lg:px-8'>
            {children}
          </div>
        )}
      </div>
    </div>
  );
}

export default Layout;
