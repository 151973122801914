import React from "react";

function ExternalLink({ label, to }) {
  return (
    <a
      className='text-blue-500 underline'
      href={to}
      target='_blank'
      rel='noopener noreferrer'
    >
      {label}
    </a>
  );
}

export default ExternalLink;
