import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { postTransaction, putTransaction } from "../../../redux/actions/transactionActions";
import { resetHandler } from "../../../redux/actions/pendingActions";
import { POST_TRANSACTION, PUT_TRANSACTION } from "../../../redux/constants/transactionConstants";
import ModalTemplate from "../../../components/modals/ModalTemplate";
import FlexRowBetween from "../../../components/structural/FlexRowBetween";
import CurrencyField from "../../../components/ui/CurrencyField";
import DateField from "../../../components/ui/DateField";
import SelectField from "../../../components/ui/SelectField";
import ModalButtons from "../../../components/modals/ModalButtons";

function IncomeModal({
	type,
	category,
	setShowModal,
	projectId,
	clientId,
	subscriptionId,
	editTransaction,
	defaultMethod = "",
}) {
	// Component State
	const [payment, setPayment] = useState({
		id: "",
		associatedProject: projectId,
		associatedClient: clientId,
		associatedSubscription: subscriptionId,
		type: "INCOME",
		category: category,
		value: 0,
		dateTransaction: new Date(),
		method: defaultMethod,
	});

	// Redux State
	const pendingState = useSelector((state) => state.pendingStates);

	// Const Declerations
	const dispatch = useDispatch();

	// Function Declerations
	const onCloseHandler = () => {
		setShowModal(false);
	};

	const onSaveHandler = () => {
		if (payment.value === 0) {
			toast.warning("Please provide value greater than 0");
			return;
		}
		if (type === "NEW") {
			dispatch(postTransaction(payment));
		} else if (type === "EDIT") {
			dispatch(putTransaction(payment));
		}
	};

	// Effect Declerations

	// if type is edit effect adds the transaction to edit into state
	useEffect(() => {
		if (type === "EDIT") {
			setPayment({
				id: editTransaction.id,
				associatedProject: editTransaction.associatedProject,
				associatedClient: editTransaction.clientId,
				associatedSubscription: editTransaction.associatedSubscription,
				type: editTransaction.type,
				category: editTransaction.category,
				value: editTransaction.value / 100,
				dateTransaction: editTransaction.dateTransaction
					? new Date(editTransaction.dateTransaction)
					: new Date(),
				method: editTransaction.method,
			});
		}
	}, [editTransaction, type]);

	// close form on success
	useEffect(() => {
		if (type === "NEW" && pendingState.POST_TRANSACTION?.loaded) {
			setShowModal(false);
			dispatch(resetHandler(POST_TRANSACTION));
		} else if (type === "EDIT" && pendingState.PUT_TRANSACTION?.loaded) {
			setShowModal(false);
			dispatch(resetHandler(PUT_TRANSACTION));
		}
	});

	return (
		<ModalTemplate title={type === "NEW" ? "New Payment" : "Edit Payment"} onClose={onCloseHandler}>
			<FlexRowBetween width='w-full'>
				<CurrencyField
					label='Value'
					value={payment.value}
					onChange={(e) => setPayment({ ...payment, value: e.target.value })}
				/>
				<DateField
					label='Date Received'
					value={payment.dateTransaction}
					onChange={(date) => setPayment({ ...payment, dateTransaction: date })}
				/>
				<SelectField
					label='Payment Method'
					options={[
						{ label: "Fiverr (Withdrawed)", value: "FIVERR" },
						{ label: "Paypal", value: "PAYPAL" },
						{ label: "BACS", value: "BACS" },
						{ label: "Stripe Invoice", value: "Stripe Invoice" },
					]}
					value={payment.method}
					onChange={(e) => setPayment({ ...payment, method: e.target.value })}
					placeholder='Select payment method...'
				/>
			</FlexRowBetween>
			<ModalButtons
				buttonOne={{ label: "Save", onClick: () => onSaveHandler() }}
				buttonTwo={{ label: "Cancel", onClick: () => onCloseHandler() }}
			/>
		</ModalTemplate>
	);
}

export default IncomeModal;
