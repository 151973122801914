import * as t from "../constants/projectConstants";
import {
  authenticatedDeleteRequest,
  authenticatedGetRequest,
  authenticatedPostRequest,
  authenticatedPutRequest,
} from "../helpers";

const projectRoute = "/project";

export const getProjects = () => async (dispatch) => {
  authenticatedGetRequest(
    dispatch,
    t.GET_PROJECTS,
    projectRoute,
    {
      toast: false,
    },
    { toast: true, message: "Error retrieving projects" }
  );
};

export const postProject = (project) => async (dispatch) => {
  authenticatedPostRequest(
    dispatch,
    t.POST_PROJECT,
    projectRoute,
    { project },
    { toast: true, message: "Project created" },
    { toast: true, message: "Error creating project" }
  );
};

export const putProject = (project) => async (dispatch) => {
  authenticatedPutRequest(
    dispatch,
    t.PUT_PROJECT,
    projectRoute,
    { project },
    { toast: true, message: "Project updated" },
    { toast: true, message: "Error updating project" }
  );
};

export const deleteProject = (project) => async (dispatch) => {
  authenticatedDeleteRequest(
    dispatch,
    t.DELETE_PROJECT,
    projectRoute,
    { project },
    { toast: true, message: "Project deleted" },
    { toast: true, message: "Error deleting project" }
  );
};
