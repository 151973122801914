export const checkPending = (process, pendingState) => {
  if (!pendingState[process]) {
    // process not called
    return false;
  } else if (pendingState[process].pending) {
    // process pending
    return true;
  } else {
    // process not pending
    return false;
  }
};

export const checkLoaded = (process, pendingState) => {
  if (!pendingState[process]) {
    // process not called
    return false;
  } else if (!pendingState[process].loaded) {
    // process not loaded
    return false;
  } else {
    // process loaded
    return true;
  }
};
