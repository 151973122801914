import React, { useEffect, useState } from "react";
import Layout from "../../components/layout";
import SearchField from "../../components/ui/SearchField";
import Button from "../../components/ui/Button";
import PortfolioModal from "./components/PortfolioModal";
import ResourceRow from "./components/ResourceRow";
import { useDispatch, useSelector } from "react-redux";
import { checkLoaded, checkPending } from "../../utils/checkPendingStatus";
import { GET_CLIENTS } from "../../redux/constants/clientConstants";
import { getClients } from "../../redux/actions/clientActions";
import { GET_PORTFOLIO } from "../../redux/constants/portfolioConstants";
import { getPortfolio } from "../../redux/actions/portfolioActions";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import FlexRowBetween from "../../components/structural/FlexRowBetween";

function PortfolioScreen() {
  // Component State
  const [showAddResourceModal, setShowAddResourceModal] = useState(false);
  const [showEditResourceModal, setShowEditResourceModal] = useState(false);
  const [editResource, setEditResource] = useState(null);

  // Redux State
  const clients = useSelector((state) => state.clients);
  const pendingState = useSelector((state) => state.pendingStates);
  const portfolio = useSelector((state) => state.portfolio);

  // Const Declerations
  const dispatch = useDispatch();

  // Function Declerations
  const clientFromId = (id) => {
    const client = clients?.filter((client) => client.id === id);
    const clientName = client ? client[0].clientName : "";
    return clientName;
  };

  const editResourceHandler = (resource) => {
    setEditResource(resource);
    setShowEditResourceModal(true);
  };

  // Effect Declerations

  // If clients not in redux state calls get clients
  useEffect(() => {
    if (
      !checkLoaded(GET_CLIENTS, pendingState) &&
      !checkPending(GET_CLIENTS, pendingState)
    ) {
      dispatch(getClients());
    }
  }, [pendingState, dispatch]);

  // If portfolio not in redux state calls get portfolio
  useEffect(() => {
    if (
      !checkLoaded(GET_PORTFOLIO, pendingState) &&
      !checkPending(GET_PORTFOLIO, pendingState)
    ) {
      dispatch(getPortfolio());
    }
  }, [pendingState, dispatch]);

  return (
    <Layout title='Portfolio'>
      <FlexRowBetween width='w-full'>
        <SearchField placeholder='Search Portfolio' />
        <div className='flex w-full lg:w-5/12'>
          <Button
            label='Add Resource'
            onClick={() => setShowAddResourceModal(true)}
          />
        </div>
      </FlexRowBetween>
      {checkPending(GET_PORTFOLIO, pendingState) && (
        <LoadingSpinner message='Loading resources' />
      )}
      {checkLoaded(GET_PORTFOLIO, pendingState) && portfolio.length > 0 && (
        <div className='flex flex-wrap mt-4'>
          {portfolio.map((resource) => (
            <ResourceRow
              key={resource.id}
              resource={resource}
              editHandler={() => editResourceHandler(resource)}
              client={clientFromId(resource.associatedClient)}
            />
          ))}
        </div>
      )}
      {checkLoaded(GET_PORTFOLIO, pendingState) && portfolio.length === 0 && (
        <div className='flex h-40 justify-center items-center bg-white mt-4 mx-12 rounded-lg'>
          <p className='text-2xl text-dashDarkBlue'>No resources added yet</p>
        </div>
      )}
      {/* Modal Components */}
      {showAddResourceModal && (
        <PortfolioModal setShowModal={setShowAddResourceModal} type='NEW' />
      )}
      {showEditResourceModal && (
        <PortfolioModal
          setShowModal={setShowEditResourceModal}
          type='EDIT'
          editResource={editResource}
        />
      )}
    </Layout>
  );
}

export default PortfolioScreen;
