import React from "react";
import Table from "../../../components/Table";
import { useSelector } from "react-redux";

export default function LeadTable() {
	const leads = useSelector((state) => state.leads);
	return (
		<div className='flex flex-col mt-4 rounded-t-xl overflow-clip'>
			<Table
				columns={[{ title: "Lead Name" }, { title: "Company" }, { title: "Email" }]}
				rows={leads.map((lead) => ({
					fields: [
						{
							value: lead.name,
						},
						{
							value: lead.company,
						},
						{
							value: lead.email,
						},
					],
				}))}
			/>
		</div>
	);
}
