import React from "react";

function NavButton({ label, icon, onClick, selected }) {
  return (
    <button
      className={`flex items-center px-8 space-x-8 text-white text-lg hover:border-t-2 hover:border-t-dashBlue hover:border-b-2 hover:border-b-dashBlue py-2 ${
        selected ? "bg-dashHighlightBlue" : ""
      }`}
      onClick={onClick}
    >
      {icon}
      <p>{label}</p>
    </button>
  );
}

export default NavButton;
