import React from "react";
import { CloseCircleOutlined } from "@ant-design/icons";
import FlexRowBetween from "../structural/FlexRowBetween";
import Button from "../ui/Button";

function FlyoverTemplate({ children, title, onClose, buttonOne, buttonTwo }) {
  return (
    <div className='flex min-h-screen bg-gray-500/75 justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none '>
      <div className='relative mt-0 mr-0 mx-auto w-full lg:w-6/12 h-screen'>
        <div className='flex flex-col justify-between min-h-screen border-0 rounded-tl rounded-bl shadow-lg relative w-full bg-white outline-none focus:outline-none'>
          <div className='flex items-center justify-between space-x-10 p-5 border-b border-solid border-gray-300 rounded-t '>
            <h3 className='text-2xl font-semibold'>{title}</h3>
            <button
              className='bg-transparent border-0 text-dashDarkBlue float-right'
              onClick={() => onClose()}
            >
              <CloseCircleOutlined className='text-3xl text-dashDarkBlue' />
            </button>
          </div>
          <div className='flex flex-col grow items-center p-4'>{children}</div>
          <FlexRowBetween width='w-full'>
            <Button
              label={buttonOne.label}
              onClick={buttonOne.onClick}
              disabled={buttonOne.disabled}
            />
            <Button
              label={buttonTwo.label}
              onClick={buttonTwo.onClick}
              disabled={buttonTwo.disabled}
            />
          </FlexRowBetween>
        </div>
      </div>
    </div>
  );
}

export default FlyoverTemplate;
