import React, { useState } from "react";
import ModalTemplate from "../../../components/modals/ModalTemplate";
import ClientSelect from "../../clients/components/ClientSelect";
import SelectField from "../../../components/ui/SelectField";
import { useSelector } from "react-redux";
import { formattedDate } from "../../../utils/formattedDate";
import ModalButtons from "../../../components/modals/ModalButtons";
import useTime from "../hooks/useTime";
import { toast } from "react-toastify";

export default function LinkTimeTransactionModal({ setShowModal, entryToLink }) {
	const [selectedTransaction, setSelectedTransaction] = useState("");
	const clients = useSelector((state) => state.clients);
	const transactions = useSelector((state) => state.transactions);

	const { linkTransaction } = useTime();

	const clientFromId = (id) => {
		const client = clients?.filter((client) => client.id === id);
		const clientName = client ? client[0]?.clientName : "";
		return clientName;
	};

	const transactionsToDisplay = transactions
		?.filter((transaction) => transaction.associatedClient === entryToLink.associatedClient)
		.map((transaction) => {
			return {
				value: transaction.id,
				label: `${clientFromId(transaction.associatedClient)} - £${
					transaction.value / 100
				} - ${formattedDate(transaction.dateTransaction)}`,
			};
		});

	const onCloseHandler = () => {
		setShowModal(false);
	};

	const onSaveHandler = () => {
		if (selectedTransaction === "") {
			toast.warning("Select a transaction to link");
			return;
		}
		linkTransaction(entryToLink, selectedTransaction);
		setShowModal(false);
	};

	return (
		<ModalTemplate title='Link Time Entry to Transaction' onClose={onCloseHandler}>
			<div className='flex flex-col w-full'>
				<ClientSelect value={entryToLink.associatedClient} disabled={true} />
				{transactions && (
					<SelectField
						label='Transaction'
						options={transactionsToDisplay}
						value={selectedTransaction}
						onChange={(e) => setSelectedTransaction(e.target.value)}
						placeholder='Select transaction..'
					/>
				)}
			</div>
			<ModalButtons
				buttonOne={{ label: "Save", onClick: () => onSaveHandler() }}
				buttonTwo={{ label: "Cancel", onClick: () => onCloseHandler() }}
			/>
		</ModalTemplate>
	);
}
