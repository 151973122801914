import axios from "axios";

const backend = process.env.REACT_APP_BACKEND;

export const expenseRoute = `${backend}/expense`;
export const subscriptionRoute = `${backend}/subscription`;
export const timeRoute = `${backend}/time`;

export const config = (userToken) => {
	return {
		headers: {
			Authorization: "Bearer " + userToken,
		},
	};
};

export const configDelete = (userToken, dataPayload) => {
	return {
		headers: {
			Authorization: "Bearer " + userToken,
		},
		data: {
			dataPayload,
		},
	};
};

export const axiosErrorHandler = (error) => {
	if (axios.isAxiosError(error)) {
		console.log("error message: ", error);
		throw new Error(error.response.data);
	} else {
		console.log("unexpected error: ", error.message);
		throw new Error(error);
	}
};
