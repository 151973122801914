import * as t from "../constants/clientConstants";
import { requestType } from "../helpers";

export const clientReducer = (state = null, action) => {
  switch (action.type) {
    case requestType(t.GET_CLIENTS, "SUCCESS"):
      return action.payload;
    case requestType(t.GET_CLIENTS, "FAILURE"):
      return [];
    case requestType(t.POST_CLIENT, "SUCCESS"):
      return [action.payload, ...state];
    case requestType(t.PUT_CLIENT, "SUCCESS"):
      const filterUpdatedClient = state.filter(
        (client) => client.id !== action.payload.id
      );
      return [action.payload, ...filterUpdatedClient];
    case requestType(t.DELETE_CLIENT, "SUCCESS"):
      const filterDeletedClient = state.filter(
        (client) => client.id !== action.payload.client.id
      );
      return filterDeletedClient;
    default:
      return state;
  }
};
