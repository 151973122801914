import React from "react";

function Button({ label, onClick, disabled }) {
	return (
		<button
			className='bg-dashDarkBlue disabled:bg-slate-50 text-dashBlue disabled:text-slate-200 lg:mx-8 px-6 py-2 rounded-lg shadow-lg w-fit'
			onClick={onClick}
			disabled={disabled}
		>
			{label}
		</button>
	);
}

export default Button;
