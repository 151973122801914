import React, { useEffect, useState } from "react";
import Layout from "../../../components/layout";
import SubscriptionsTable from "../components/SubscriptionsTable";
import SubscriptionsModal from "../components/SubscriptionsModal";
import { useDispatch, useSelector } from "react-redux";
import useSubscriptions from "../hooks/useSubscriptions";
import { checkLoaded, checkPending } from "../../../utils/checkPendingStatus";
import { GET_TRANSACTION } from "../../../redux/constants/transactionConstants";
import { getTransactions } from "../../../redux/actions/transactionActions";

export default function SubscriptionsRoute() {
	const [showNewSubscription, setShowNewSubscription] = useState(false);

	const pendingState = useSelector((state) => state.pendingStates);
	const subscriptionsState = useSelector((state) => state.subscriptions);

	const { getSubscriptions } = useSubscriptions();

	const dispatch = useDispatch();

	useEffect(() => {
		if (!subscriptionsState.loaded) {
			getSubscriptions();
		}
	}, [subscriptionsState.loaded, getSubscriptions]);

	useEffect(() => {
		if (
			!checkLoaded(GET_TRANSACTION, pendingState) &&
			!checkPending(GET_TRANSACTION, pendingState)
		) {
			dispatch(getTransactions());
		}
	}, [pendingState, dispatch]);

	return (
		<Layout title='Subscriptions'>
			<div className='flex justify-end'>
				<button
					className='px-4 py-2 bg-dashDarkBlue text-dashBlue rounded-xl shadow-lg'
					onClick={() => setShowNewSubscription(true)}
				>
					Create Subscription
				</button>
			</div>
			<SubscriptionsTable subscriptions={subscriptionsState.subscriptions} />
			{showNewSubscription && (
				<SubscriptionsModal type='NEW' setShowModal={setShowNewSubscription} />
			)}
		</Layout>
	);
}
