import axios from "axios";
import { axiosErrorHandler, config, subscriptionRoute } from "../../../app/api";

export const getSubscriptionsApi = async (userToken) => {
	try {
		const { data } = await axios.get(subscriptionRoute, config(userToken));
		return data;
	} catch (error) {
		return axiosErrorHandler(error);
	}
};
