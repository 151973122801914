import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import React from "react";
import { mapStageToString } from "../../../utils/mapToString";
import { useDispatch } from "react-redux";
import { deleteClient } from "../../../redux/actions/clientActions";

function ClientCard({ client, editHandler }) {
  const dispatch = useDispatch();

  const deleteClientHandler = () => {
    dispatch(deleteClient(client));
  };

  return (
    <div className='flex flex-col w-full lg:w-3/12 mx-12 mb-8 bg-white rounded-lg '>
      <div className='flex justify-between'>
        <div className='px-2 text-white bg-dashBlue rounded-tl-lg rounded-br-lg'>
          <p>{mapStageToString(client.status)}</p>
        </div>
        <div className='px-2 bg-slate-100 rounded-tr-lg rounded-bl-lg space-x-2'>
          <button className='mb-1 px-1' onClick={editHandler}>
            <EditOutlined />
          </button>
          <button className='mb-1 px-1' onClick={() => deleteClientHandler()}>
            <DeleteOutlined />
          </button>
        </div>
      </div>
      <p className='font-bold text-center mb-4 p-4'>{client.clientName}</p>

      <div className='flex flex-col p-4'>
        <p>Contact</p>
        <p className='font-light text-left'>{client.contactName}</p>
        <p className='font-light text-left'>{client.contactEmail}</p>
      </div>
    </div>
  );
}

export default ClientCard;
