import { useState } from "react";
import { useDispatch } from "react-redux";
import { getSubscriptionsApi } from "../api/get.subscription";
import { setSubscriptions, updateSubscriptions } from "../store/subscription.slice";
import { firebaseAuth } from "../../../firebase";
import { createSubscriptionApi } from "../api/post.subscription";
import { updateSubscriptionApi } from "../api/put.subscription";
import { toast } from "react-toastify";

export default function useSubscriptions() {
	const [isLoading, setIsLoading] = useState(false);
	const [actionSuccessful, setActionSuccessful] = useState(false);

	const dispatch = useDispatch();

	const getSubscriptions = async () => {
		setIsLoading(true);
		firebaseAuth.currentUser?.getIdToken(true).then(async (idToken) => {
			try {
				const subscriptions = await getSubscriptionsApi(idToken);
				dispatch(setSubscriptions(subscriptions));
			} catch (error) {
				console.log(error);
				toast.error("Error retrieving subscriptions");
			} finally {
				setIsLoading(false);
			}
		});
	};

	const createSubscription = async (subscription) => {
		setIsLoading(true);
		firebaseAuth.currentUser?.getIdToken(true).then(async (idToken) => {
			try {
				const newSubscription = await createSubscriptionApi(subscription, idToken);
				dispatch(updateSubscriptions(newSubscription));
				setActionSuccessful(true);
				toast.success("Subscription Created");
			} catch (error) {
				console.log(error);
				toast.error("Error creating subscription");
			} finally {
				setIsLoading(false);
			}
		});
	};

	const updateSubscription = async (subscription) => {
		setIsLoading(true);
		firebaseAuth.currentUser?.getIdToken(true).then(async (idToken) => {
			try {
				const updatedSubscription = await updateSubscriptionApi(subscription, idToken);
				dispatch(updateSubscriptions(updatedSubscription));
				setActionSuccessful(true);
				toast.success("Subscription Updated");
			} catch (error) {
				console.log(error);
				toast.error("Error updating subscription");
			} finally {
				setIsLoading(false);
			}
		});
	};

	return { getSubscriptions, createSubscription, updateSubscription, isLoading, actionSuccessful };
}
