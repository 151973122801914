import React, { useEffect } from "react";
import Layout from "../../../components/layout";
import TimeTracker from "../components/TimeTracker";
import { useSelector } from "react-redux";
import useTime from "../hooks/useTime";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import TrackedTimeTable from "../components/TrackedTimeTable";

export default function TimeTrackingRoute() {
	const timeState = useSelector((state) => state.time);

	const { getTimeEntries, isLoading } = useTime();

	useEffect(() => {
		if (!timeState.loaded && !isLoading) {
			getTimeEntries();
		}
	}, [timeState.loaded, getTimeEntries, isLoading]);

	if (!timeState.loaded)
		return (
			<Layout title='Time Tracking'>
				<LoadingSpinner message='Loading Time Entries' />
			</Layout>
		);

	return (
		<Layout title='Time Tracking'>
			<TimeTracker />
			<TrackedTimeTable
				// filter out new (unstarted) and active timers
				trackedTime={timeState.timeEntries
					.filter((time) => time.status !== "NEW" && time.status !== "ACTIVE")
					.sort((a, b) => new Date(b.startTime) - new Date(a.startTime))}
			/>
		</Layout>
	);
}
