import React from "react";

function CurrencyField({ label, value, onChange, disabled }) {
  return (
    <div className='flex flex-col w-[140px] mt-2 mb-4 mx-4'>
      <label className='mb-2'>{label}</label>
      <div className='flex mt-2 items-center border rounded-lg px-2'>
        <p className='mr-1'>£</p>
        <input
          className='h-10 w-[110px] outline-none rounded-lg'
          value={value}
          type='number'
          onChange={onChange}
          disabled={disabled}
          step={0.5}
        />
      </div>
    </div>
  );
}

export default CurrencyField;
