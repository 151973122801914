import axios from "axios";
import { axiosErrorHandler, config, timeRoute } from "../../../app/api";

export const stopTimeEntryApi = async (timeEntry, userToken) => {
	try {
		const { data } = await axios.put(`${timeRoute}/stop`, { timeEntry }, config(userToken));
		return data;
	} catch (error) {
		return axiosErrorHandler(error);
	}
};

export const updateTimeEntryStatusApi = async (timeEntry, status, userToken) => {
	try {
		const { data } = await axios.put(
			`${timeRoute}/status`,
			{ timeEntry, status },
			config(userToken)
		);
		return data;
	} catch (error) {
		return axiosErrorHandler(error);
	}
};

export const linkTransactionApi = async (timeEntry, transaction, userToken) => {
	try {
		const { data } = await axios.put(
			`${timeRoute}/transaction`,
			{ timeEntry, transaction },
			config(userToken)
		);
		return data;
	} catch (error) {
		return axiosErrorHandler(error);
	}
};
