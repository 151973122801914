import * as t from "../constants/portfolioConstants";
import {
  authenticatedDeleteRequest,
  authenticatedGetRequest,
  authenticatedPostRequest,
  authenticatedPutRequest,
} from "../helpers";

const portfolioRoute = "/portfolio";

export const getPortfolio = () => async (dispatch) => {
  authenticatedGetRequest(
    dispatch,
    t.GET_PORTFOLIO,
    portfolioRoute,
    {
      toast: false,
    },
    { toast: true, message: "Error retrieving resources" }
  );
};

export const postPortfolio = (portfolio) => async (dispatch) => {
  authenticatedPostRequest(
    dispatch,
    t.POST_PORTFOLIO,
    portfolioRoute,
    { portfolio },
    { toast: true, message: "Resource created" },
    { toast: true, message: "Error creating resource" }
  );
};

export const putPortfolio = (portfolio) => async (dispatch) => {
  authenticatedPutRequest(
    dispatch,
    t.PUT_PORTFOLIO,
    portfolioRoute,
    { portfolio },
    { toast: true, message: "Resource updated" },
    { toast: true, message: "Error updating resource" }
  );
};

export const deletePortfolio = (portfolio) => async (dispatch) => {
  authenticatedDeleteRequest(
    dispatch,
    t.DELETE_PORTFOLIO,
    portfolioRoute,
    { portfolio },
    { toast: true, message: "Resource Deleted" },
    { toast: true, message: "Error deleting resource" }
  );
};
