import * as t from "../constants/projectConstants";
import { requestType } from "../helpers";

export const projectReducer = (state = null, action) => {
  switch (action.type) {
    case requestType(t.GET_PROJECTS, "SUCCESS"):
      return action.payload;
    case requestType(t.GET_PROJECTS, "FAILURE"):
      return [];
    case requestType(t.POST_PROJECT, "SUCCESS"):
      return [action.payload, ...state];
    case requestType(t.PUT_PROJECT, "SUCCESS"):
      const filterUpdatedProject = state.filter(
        (project) => project.id !== action.payload.id
      );
      return [action.payload, ...filterUpdatedProject];
    case requestType(t.DELETE_PROJECT, "SUCCESS"):
      const filterDeletedProject = state.filter(
        (project) => project.id !== action.payload.project.id
      );
      return filterDeletedProject;
    default:
      return state;
  }
};
