import React from "react";

function ResourceRowSection({ label, value }) {
  return (
    <div className='flex flex-col w-[180px] items-center mb-2 px-4 lg:border-r'>
      <p className='underline mb-1'>{label}</p>
      <p className=''>{value}</p>
    </div>
  );
}

export default ResourceRowSection;
