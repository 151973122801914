import { useState } from "react";
import { useDispatch } from "react-redux";
import { firebaseAuth } from "../../../firebase";
import { createTimeEntryApi } from "../api/post.time";
import { toast } from "react-toastify";
import { linkTransactionApi, stopTimeEntryApi, updateTimeEntryStatusApi } from "../api/put.time";
import { getTimeEntriesApi } from "../api/get.time";
import { setTimeEntries, updateTimeEntries } from "../store/time.slice";

export default function useTime() {
	const [isLoading, setIsLoading] = useState(false);

	const dispatch = useDispatch();

	const getTimeEntries = async () => {
		setIsLoading(true);
		firebaseAuth.currentUser?.getIdToken(true).then(async (idToken) => {
			try {
				const timeEntries = await getTimeEntriesApi(idToken);
				dispatch(setTimeEntries(timeEntries));
			} catch (error) {
				console.log(error);
				toast.error("Error retrieving time entries");
			} finally {
				setIsLoading(false);
			}
		});
	};

	const createTimeEntry = async (timeEntry, setTimeEntry) => {
		setIsLoading(true);
		firebaseAuth.currentUser?.getIdToken(true).then(async (idToken) => {
			try {
				const newTimeEntry = await createTimeEntryApi(timeEntry, idToken);
				dispatch(updateTimeEntries(newTimeEntry));
				setTimeEntry(newTimeEntry);
				toast.success("Time Entry Created");
			} catch (error) {
				console.log(error);
				toast.error("Error creating time entry");
			} finally {
				setIsLoading(false);
			}
		});
	};

	const stopTimeEntry = async (timeEntry, setTimeEntry) => {
		setIsLoading(true);
		firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
			try {
				const newTimeEntry = await stopTimeEntryApi(timeEntry, idToken);
				dispatch(updateTimeEntries(newTimeEntry));
				setTimeEntry(newTimeEntry);
				toast.success("Timer ended");
			} catch (error) {
				console.log(error);
				toast.error("Error ending time entry");
			} finally {
				setIsLoading(false);
			}
		});
	};

	const updateTimeEntryStatus = async (timeEntry, status) => {
		setIsLoading(true);
		firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
			try {
				const updateTimeEntry = await updateTimeEntryStatusApi(timeEntry, status, idToken);
				dispatch(updateTimeEntries(updateTimeEntry));
				toast.success("Status updated");
			} catch (error) {
				console.log(error);
				toast.error("Error updating status");
			} finally {
				setIsLoading(false);
			}
		});
	};

	const linkTransaction = async (timeEntry, transaction) => {
		setIsLoading(true);
		firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
			try {
				const updateTimeEntry = await linkTransactionApi(timeEntry, transaction, idToken);
				dispatch(updateTimeEntries(updateTimeEntry));
				toast.success("Transaction linked to entry");
			} catch (error) {
				console.log(error);
				toast.error("Error linking transaction to entry");
			} finally {
				setIsLoading(false);
			}
		});
	};

	return {
		isLoading,
		getTimeEntries,
		createTimeEntry,
		stopTimeEntry,
		updateTimeEntryStatus,
		linkTransaction,
	};
}
