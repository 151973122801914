import React from "react";
import { toast } from "react-toastify";

function CopyClipboardButton({ textToCopy, label }) {
  const copyToClipboardHandler = () => {
    navigator.clipboard.writeText(textToCopy);
    toast.success("Copied");
  };

  return (
    <button
      className='text-blue-500 underline'
      onClick={() => copyToClipboardHandler()}
    >
      {label}
    </button>
  );
}

export default CopyClipboardButton;
