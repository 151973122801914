import * as t from "../constants/statsConstants";
import { authenticatedGetRequest } from "../helpers";

const statsRoute = "/stats";

export const getStats = () => async (dispatch) => {
  authenticatedGetRequest(
    dispatch,
    t.GET_STATS,
    statsRoute,
    {
      toast: false,
    },
    { toast: true, message: "Error retrieving stats" }
  );
};
