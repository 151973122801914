import React from "react";

function NumberField({ label, value, placeholder, autoComplete, onChange, disabled }) {
	return (
		<div className='flex flex-col w-[180px] mt-2 mb-4'>
			<label className='mb-2'>{label}</label>
			<input
				className='p-2 mt-2 h-10 border rounded-lg'
				value={value}
				type='number'
				placeholder={placeholder}
				autoComplete={autoComplete}
				onChange={onChange}
				disabled={disabled}
			/>
		</div>
	);
}

export default NumberField;
