import React, { useEffect, useState } from "react";
import ModalTemplate from "../../../components/modals/ModalTemplate";
import ModalButtons from "../../../components/modals/ModalButtons";
import FlexRowBetween from "../../../components/structural/FlexRowBetween";
import TextField from "../../../components/ui/TextField";
import SelectField from "../../../components/ui/SelectField";
import { useDispatch, useSelector } from "react-redux";
import CheckField from "../../../components/ui/CheckField";
import { toast } from "react-toastify";
import {
  postPortfolio,
  putPortfolio,
} from "../../../redux/actions/portfolioActions";

function PortfolioModal({ setShowModal, type, editResource }) {
  // Component State
  const [portfolio, setPortfolio] = useState({
    id: "",
    name: "",
    associatedClient: "",
    productionUrl: "",
    services: {
      frontend: false,
      frontendHosting: "",
      frontendProductionURL: "",
      server: false,
      serverHosting: "",
      serverUrl: "",
      serverIp: "",
      database: false,
      databaseHosting: "",
    },
  });

  // Redux State
  const clients = useSelector((state) => state.clients);

  // Const Declerations
  const dispatch = useDispatch();

  const clientOptions = clients.map((item) => {
    return {
      value: item.id,
      label: item.clientName,
    };
  });

  // Function Declerations
  const onCloseHandler = () => {
    setShowModal(false);
  };

  const onSaveHandler = () => {
    if (portfolio.name.trim() === "") {
      toast.warning("Please enter resource name");
      return;
    }
    if (type === "NEW") {
      dispatch(postPortfolio(portfolio));
    } else if (type === "EDIT") {
      dispatch(putPortfolio(portfolio));
    }
  };

  // Effect Declerations

  // If type is edit effect adds the project to edit into state
  useEffect(() => {
    if (type === "EDIT") {
      setPortfolio({
        id: editResource.id,
        name: editResource.name,
        associatedClient: editResource.associatedClient,
        productionUrl: editResource.productionUrl,
        services: editResource.services,
      });
    }
  }, [editResource, type]);

  return (
    <ModalTemplate title='Add Resource' onClose={() => onCloseHandler()}>
      <FlexRowBetween width='w-full'>
        <TextField
          label='Resource Name'
          value={portfolio.name}
          onChange={(e) => setPortfolio({ ...portfolio, name: e.target.value })}
        />
        <SelectField
          label='Client'
          options={clientOptions}
          value={portfolio.associatedClient}
          onChange={(e) =>
            setPortfolio({ ...portfolio, associatedClient: e.target.value })
          }
          placeholder='Select client...'
        />
      </FlexRowBetween>
      <p className='w-full px-8 text-left font-bold text-xl'>Services</p>
      <FlexRowBetween width='w-full'>
        <CheckField
          label='Frontend'
          value={portfolio.services.frontend}
          onChange={() =>
            setPortfolio({
              ...portfolio,
              services: {
                ...portfolio.services,
                frontend: !portfolio.services.frontend,
              },
            })
          }
        />
        <CheckField
          label='Server'
          value={portfolio.services.server}
          onChange={() =>
            setPortfolio({
              ...portfolio,
              services: {
                ...portfolio.services,
                server: !portfolio.services.server,
              },
            })
          }
        />
        <CheckField
          label='Database'
          value={portfolio.services.database}
          onChange={() =>
            setPortfolio({
              ...portfolio,
              services: {
                ...portfolio.services,
                database: !portfolio.services.database,
              },
            })
          }
        />
      </FlexRowBetween>
      {portfolio.services.frontend && (
        <FlexRowBetween width='w-full'>
          <SelectField
            label='Frontend Production Host'
            options={[
              { label: "Firebase", value: "Firebase" },
              { label: "DigitalOcean", value: "DigitalOcean" },
            ]}
            value={portfolio.services.frontendHosting}
            onChange={(e) =>
              setPortfolio({
                ...portfolio,
                services: {
                  ...portfolio.services,
                  frontendHosting: e.target.value,
                },
              })
            }
            placeholder='Select Frontend Hosting Provider...'
          />
        </FlexRowBetween>
      )}
      {portfolio.services.frontend && (
        <FlexRowBetween width='w-full'>
          <TextField
            label='Frontend Production URL'
            value={portfolio.services.frontendProductionURL}
            onChange={(e) =>
              setPortfolio({
                ...portfolio,
                services: {
                  ...portfolio.services,
                  frontendProductionURL: e.target.value,
                },
              })
            }
          />
        </FlexRowBetween>
      )}
      {portfolio.services.server && (
        <FlexRowBetween width='w-full'>
          <SelectField
            label='Server Production Host'
            options={[{ label: "DigitalOcean", value: "DigitalOcean" }]}
            value={portfolio.services.serverHosting}
            onChange={(e) =>
              setPortfolio({
                ...portfolio,
                services: {
                  ...portfolio.services,
                  serverHosting: e.target.value,
                },
              })
            }
            placeholder='Select Server Hosting Provider...'
          />
        </FlexRowBetween>
      )}
      {portfolio.services.server && (
        <FlexRowBetween width='w-full'>
          <TextField
            label='Server Production URL'
            value={portfolio.services.serverUrl}
            onChange={(e) =>
              setPortfolio({
                ...portfolio,
                services: {
                  ...portfolio.services,
                  serverUrl: e.target.value,
                },
              })
            }
          />
        </FlexRowBetween>
      )}
      {portfolio.services.server && (
        <FlexRowBetween width='w-full'>
          <TextField
            label='Server IP Address'
            value={portfolio.services.serverIp}
            onChange={(e) =>
              setPortfolio({
                ...portfolio,
                services: {
                  ...portfolio.services,
                  serverIp: e.target.value,
                },
              })
            }
          />
        </FlexRowBetween>
      )}
      {portfolio.services.database && (
        <FlexRowBetween width='w-full'>
          <SelectField
            label='Database Production Host'
            options={[
              { label: "DigitalOcean", value: "DigitalOcean" },
              { label: "Firebase", value: "Firebase" },
            ]}
            value={portfolio.services.databaseHosting}
            onChange={(e) =>
              setPortfolio({
                ...portfolio,
                services: {
                  ...portfolio.services,
                  databaseHosting: e.target.value,
                },
              })
            }
            placeholder='Select Database Hosting Provider...'
          />
        </FlexRowBetween>
      )}
      <ModalButtons
        buttonOne={{ label: "Save", onClick: () => onSaveHandler() }}
        buttonTwo={{ label: "Cancel", onClick: () => onCloseHandler() }}
      />
    </ModalTemplate>
  );
}

export default PortfolioModal;
