import React from "react";
import { useDispatch } from "react-redux";
import { signOutUser } from "../../../../../redux/actions/authActions";

function UserMenu() {
  const dispatch = useDispatch();
  return (
    <div className='flex flex-col w-full bg-white absolute top-10 justify-center border-r border-l border-b rounded-bl-lg rounded-br-lg'>
      <button
        className='px-4 py-2 hover:bg-slate-50 rounded-bl-lg rounded-br-lg'
        onClick={() => dispatch(signOutUser())}
      >
        Sign Out
      </button>
    </div>
  );
}

export default UserMenu;
