import axios from "axios";
import { axiosErrorHandler, config, timeRoute } from "../../../app/api";

export const getTimeEntriesApi = async (userToken) => {
	try {
		const { data } = await axios.get(timeRoute, config(userToken));
		return data;
	} catch (error) {
		return axiosErrorHandler(error);
	}
};
