import React from "react";

function FlexRowBetween({ children, width }) {
  return (
    <div
      className={`flex flex-col lg:flex-row justify-between items-center ${width} mt-2`}
    >
      {children}
    </div>
  );
}

export default FlexRowBetween;
