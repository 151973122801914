import React from "react";

function Table({ columns, rows }) {
	return (
		<table className='table-auto border w-full mx-auto'>
			<thead>
				<tr className='bg-white text-slate-700 text-sm border-b-2 border-slate-300'>
					<th className='py-2 border-r border-slate-300'>#</th>
					{columns.map((column, index) => (
						<th key={index} className={`border-r border-slate-300 ${column.className}`}>
							{column.title}
						</th>
					))}
				</tr>
			</thead>
			<tbody>
				{rows?.map((row, index) => (
					<tr key={index} className='bg-white text-center text-sm border-b'>
						<td className='py-2'>{index + 1}</td>
						{row.fields.map((field, index) => (
							<td key={index} className={field.className}>
								{field.value}
							</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	);
}

export default Table;
