import * as t from "../constants/transactionConstants";
import { requestType } from "../helpers";

export const transactionReducer = (state = null, action) => {
	switch (action.type) {
		case requestType(t.GET_TRANSACTION, "SUCCESS"):
			return action.payload;
		case requestType(t.GET_TRANSACTION, "FAILURE"):
			return [];
		case requestType(t.POST_TRANSACTION, "SUCCESS"):
			if (state) {
				return [action.payload, ...state];
			} else {
				return [action.payload];
			}
		case requestType(t.PUT_TRANSACTION, "SUCCESS"):
			const filterUpdatedTransaction = state.filter(
				(resource) => resource.id !== action.payload.id
			);
			return [action.payload, ...filterUpdatedTransaction];
		case requestType(t.DELETE_TRANSACTION, "SUCCESS"):
			const filterDeletedTransaction = state.filter(
				(resource) => resource.id !== action.payload.transaction.id
			);
			return filterDeletedTransaction;
		default:
			return state;
	}
};
