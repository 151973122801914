import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { markNotAuthenticated, signInUserToken } from "./redux/actions/authActions";
import LoadingSpinner from "./components/ui/LoadingSpinner";
import DashboardScreen from "./routes/Dashboard";
import LoginScreen from "./routes/Login";
import RegisterScreen from "./routes/Register";
import ForgotPasswordScreen from "./routes/ForgotPassword";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InboxScreen from "./routes/Inbox";
import ClientsScreen from "./routes/Clients";
import ProjectsScreen from "./routes/Projects";
import { onAuthStateChanged } from "firebase/auth";
import { firebaseAuth } from "./firebase";
import OrganisationScreen from "./routes/Organisation";
import PortfolioScreen from "./routes/Portfolio";
import TeamScreen from "./routes/Team";
import IncomeScreen from "./routes/Income";
import LeadsScreen from "./routes/Leads";
import ExpensesRoute from "./features/expenses/routes/ExpensesRoute";
import TimeTrackingRoute from "./features/time/routes/TimeTrackingRoute";
import SubscriptionsRoute from "./features/subscriptions/routes/SubscriptionsRoute";

function App() {
	const auth = useSelector((state) => state.auth);

	const dispatch = useDispatch();

	useEffect(() => {
		if (auth.signedIn === null) {
			const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
				if (user) {
					dispatch(signInUserToken(user.accessToken));
				} else {
					dispatch(markNotAuthenticated());
				}
			});
			unsubscribe();
		}
	}, [auth, dispatch]);

	return (
		<BrowserRouter>
			{auth.signedIn === null ? (
				<div className='flex flex-col w-full h-screen justify-center items-center'>
					<LoadingSpinner message='Welcome to aaWebDev Admin Panel' />
				</div>
			) : auth.signedIn ? (
				<Routes>
					<Route path='/' element={<DashboardScreen />} />
					<Route path='/clients' element={<ClientsScreen />} />
					<Route path='/expenses' element={<ExpensesRoute />} />
					<Route path='/inbox' element={<InboxScreen />} />
					<Route path='/income' element={<IncomeScreen />} />
					<Route path='/leads' element={<LeadsScreen />} />
					<Route path='/organisation' element={<OrganisationScreen />} />
					<Route path='/portfolio' element={<PortfolioScreen />} />
					<Route path='/projects' element={<ProjectsScreen />} />
					<Route path='/subscriptions' element={<SubscriptionsRoute />} />
					<Route path='/team' element={<TeamScreen />} />
					<Route path='/time-tracking' element={<TimeTrackingRoute />} />
					<Route path='*' element={<Navigate to='/' />} />
				</Routes>
			) : (
				<Routes>
					<Route path='/' element={<LoginScreen />} />
					<Route path='/register' element={<RegisterScreen />} />
					<Route path='/forgot-password' element={<ForgotPasswordScreen />} />
					<Route path='*' element={<Navigate to='/' />} />
				</Routes>
			)}
			<ToastContainer autoClose={2000} position='bottom-left' />
		</BrowserRouter>
	);
}

export default App;
