import React from "react";
import DatePicker from "react-date-picker";
import "./DatePicker.css";
import "react-calendar/dist/Calendar.css";

function DateField({ label, value, onChange }) {
	return (
		<div className='flex flex-col justify-center w-[180px] mt-2 mb-4 grow'>
			<label className='mb-2'>{label}</label>
			<DatePicker
				value={value}
				onChange={onChange}
				className='h-10 mt-2 border rounded-lg p-2 bg-white'
				calendarClassName=''
				calendarAriaLabel='Toggle calendar'
				clearIcon={null}
				showLeadingZeros={true}
			/>
		</div>
	);
}

export default DateField;
