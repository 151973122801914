import axios from "axios";
import { axiosErrorHandler, config, subscriptionRoute } from "../../../app/api";

export const createSubscriptionApi = async (subscription, userToken) => {
	console.log(subscription);
	try {
		const { data } = await axios.post(subscriptionRoute, { subscription }, config(userToken));
		return data;
	} catch (error) {
		return axiosErrorHandler(error);
	}
};
