import React from "react";
import {
	ApartmentOutlined,
	ContactsOutlined,
	CrownOutlined,
	DashboardOutlined,
	FieldTimeOutlined,
	FundViewOutlined,
	LayoutOutlined,
	PoundCircleOutlined,
	ProjectOutlined,
	SunOutlined,
	TeamOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import NavSection from "./components/NavSection";
import { useSelector } from "react-redux";

function Sidebar({ desktop }) {
	const auth = useSelector((state) => state.auth);
	const navigate = useNavigate();

	return (
		<div
			className={`${
				desktop ? "hidden lg:flex" : "flex"
			} flex-col w-[1500px] lg:w-[260px] bg-dashDarkBlue text-white drop-shadow-sm `}
		>
			<div className='flex flex-col h-20 items-center justify-center'>
				<p>{auth.user.companyDetails?.companyName}</p>
			</div>
			<NavSection
				label=''
				navButtons={[
					{
						label: "Dashboard",
						icon: <DashboardOutlined />,
						onClick: () => navigate("/"),
					},
				]}
			/>
			<NavSection
				label='CRM'
				navButtons={[
					{
						label: "Clients",
						icon: <CrownOutlined />,
						onClick: () => navigate("/clients"),
					},
					{
						label: "Leads",
						icon: <ContactsOutlined />,
						onClick: () => navigate("/leads"),
					},
				]}
			/>
			<NavSection
				label='Workspace'
				navButtons={[
					{
						label: "Projects",
						icon: <ProjectOutlined />,
						onClick: () => navigate("/projects"),
					},
					{
						label: "Portfolio",
						icon: <LayoutOutlined />,
						onClick: () => navigate("/portfolio"),
					},
					{
						label: "Time Tracker",
						icon: <FieldTimeOutlined />,
						onClick: () => navigate("/time-tracking"),
					},
					{
						label: "Subscriptions",
						icon: <SunOutlined />,
						onClick: () => navigate("/subscriptions"),
					},
				]}
			/>

			<NavSection
				label='Finance'
				navButtons={[
					{
						label: "Income",
						icon: <FundViewOutlined />,
						onClick: () => navigate("/income"),
					},
					{
						label: "Expenses",
						icon: <PoundCircleOutlined />,
						onClick: () => navigate("/expenses"),
					},
				]}
			/>
			<NavSection
				label='Admin'
				navButtons={[
					{
						label: "Organisation",
						icon: <ApartmentOutlined />,
						onClick: () => navigate("/organisation"),
					},
					{
						label: "Team",
						icon: <TeamOutlined />,
						onClick: () => navigate("/team"),
					},
				]}
			/>
		</div>
	);
}

export default Sidebar;
