import React, { useEffect, useState } from "react";
import Layout from "../../components/layout";
import { useDispatch, useSelector } from "react-redux";
import { checkLoaded, checkPending } from "../../utils/checkPendingStatus";
import { deleteTransaction, getTransactions } from "../../redux/actions/transactionActions";
import { GET_TRANSACTION } from "../../redux/constants/transactionConstants";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import Table from "../../components/Table";
import { formattedDate } from "../../utils/formattedDate";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import ConfirmActionModal from "../../components/ConfirmActionModal";
import IncomeModal from "../../features/income/components/IncomeModal";

function IncomeScreen() {
	const [showEditPaymentModal, setShowEditPaymentModal] = useState(false);
	const [showConfirmDeleteTransaction, setShowConfirmDeleteTransaction] = useState(false);
	const [transactionToEdit, setTransactionToEdit] = useState(null);
	const [transactionToDelete, setTransactionToDelete] = useState(null);

	// Redux State
	const clients = useSelector((state) => state.clients);
	const pendingState = useSelector((state) => state.pendingStates);
	const transactions = useSelector((state) => state.transactions);

	const dispatch = useDispatch();

	const clientFromId = (id) => {
		const client = clients?.filter((client) => client.id === id);
		const clientName = client ? client[0]?.clientName : "";
		return clientName;
	};

	const editTransactionHandler = (transaction) => {
		setTransactionToEdit(transaction);
		setShowEditPaymentModal(true);
	};

	const deleteTransactionHandler = (transaction) => {
		setTransactionToDelete(transaction);
		setShowConfirmDeleteTransaction(true);
	};

	// If transactions not in redux state calls get projects
	useEffect(() => {
		if (
			!checkLoaded(GET_TRANSACTION, pendingState) &&
			!checkPending(GET_TRANSACTION, pendingState)
		) {
			dispatch(getTransactions());
		}
	}, [pendingState, dispatch]);

	return (
		<Layout title='Income'>
			{checkPending(GET_TRANSACTION, pendingState) && (
				<LoadingSpinner message='Loading transactions' />
			)}

			{checkLoaded(GET_TRANSACTION, pendingState) && transactions?.length > 0 && (
				<div className='flex flex-wrap mt-4 rounded-t-xl overflow-clip'>
					<Table
						columns={[
							{ title: "Client" },
							{ title: "Category" },
							{ title: "Value" },
							{ title: "Date" },
							{ title: "Actions" },
						]}
						rows={transactions
							.filter((transaction) => transaction.type === "INCOME")
							.map((transaction) => ({
								fields: [
									{
										value: clientFromId(transaction.associatedClient),
									},
									{
										value: `£${(transaction.value / 100).toFixed(2)}`,
									},
									{
										value: formattedDate(transaction.dateTransaction),
									},
									{
										value: transaction.category,
									},
									{
										value: (
											<div className='flex items-center justify-center space-x-4'>
												<button onClick={() => deleteTransactionHandler(transaction)}>
													<DeleteOutlined />
												</button>
												<button onClick={() => editTransactionHandler(transaction)}>
													<EditOutlined />
												</button>
											</div>
										),
									},
								],
							}))}
					/>
				</div>
			)}
			{checkLoaded(GET_TRANSACTION, pendingState) &&
				transactions.filter((transaction) => transaction.type === "INCOME").length === 0 && (
					<div className='flex h-40 justify-center items-center bg-white mt-4 mx-12 rounded-lg'>
						<p className='text-2xl text-dashDarkBlue'>No transactions to display</p>
					</div>
				)}
			{showEditPaymentModal && (
				<IncomeModal
					type='EDIT'
					setShowModal={setShowEditPaymentModal}
					editTransaction={transactionToEdit}
				/>
			)}
			{showConfirmDeleteTransaction && (
				<ConfirmActionModal
					label='Confirm you wish to delete transaction?'
					onConfirm={() => {
						dispatch(deleteTransaction(transactionToDelete));
						setShowConfirmDeleteTransaction(false);
					}}
					onCancel={() => setShowConfirmDeleteTransaction(false)}
				/>
			)}
		</Layout>
	);
}

export default IncomeScreen;
