import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	timeEntries: [],
	loaded: false,
};

export const timeEntriesSlice = createSlice({
	name: "timeEntries",
	initialState,
	reducers: {
		setTimeEntries: (state, action) => {
			state.timeEntries = action.payload;
			state.loaded = true;
		},
		updateTimeEntries: (state, action) => {
			const newState = state.timeEntries.filter((timeEntry) => timeEntry.id !== action.payload.id);
			state.timeEntries = [...newState, action.payload];
			state.loaded = true;
		},
	},
});

export const { setTimeEntries, updateTimeEntries } = timeEntriesSlice.actions;
export default timeEntriesSlice.reducer;
