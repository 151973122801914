import React, { useEffect } from "react";
import Layout from "../../components/layout";
import { useDispatch, useSelector } from "react-redux";
import { checkLoaded, checkPending } from "../../utils/checkPendingStatus";
import { GET_STATS } from "../../redux/constants/statsConstants";
import { getStats } from "../../redux/actions/statsActions";
import SingleFigureStat from "./components/SingleFigureStat";
import PieChart from "./components/PieChart";

function DashboardScreen() {
	const pendingState = useSelector((state) => state.pendingStates);
	const stats = useSelector((state) => state.stats);

	const dispatch = useDispatch();

	useEffect(() => {
		if (!checkLoaded(GET_STATS, pendingState) && !checkPending(GET_STATS, pendingState)) {
			dispatch(getStats());
		}
	}, [pendingState, dispatch]);

	return (
		<Layout title='Dashboard'>
			{checkLoaded(GET_STATS, pendingState) && (
				<div className='flex flex-col'>
					<div className='flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:justify-between'>
						<SingleFigureStat
							label='Year To Date Earnings'
							stat={`£${(stats.yearToDate / 100).toFixed(2)}`}
						/>
						<SingleFigureStat
							label='2023/2024 Earnings'
							stat={`£${(stats.twentyThreeTaxYear / 100).toFixed(2)}`}
						/>
						<SingleFigureStat
							label='2022/2023 Earnings'
							stat={`£${(stats.twentyTwoTaxYear / 100).toFixed(2)}`}
						/>
						<SingleFigureStat
							label='Total All Time Earnings'
							stat={`£${(stats.totalEarnings / 100).toFixed(2)}`}
						/>
					</div>
					<div className='flex flex-col lg:flex-row lg:justify-between'>
						<PieChart
							label='Projects by Source'
							data={{
								labels: ["Fiverr", "Website Query", "Referral"],
								datasets: [
									{
										label: "# of Projects",
										data: [
											stats.totalSourceFiverrProjects,
											stats.totalSourceWebsiteProjects,
											stats.totalSourceReferralProjects,
										],
										borderWidth: 1,
									},
								],
							}}
						/>
						<PieChart
							label='Projects by Client'
							data={{
								labels: stats.clientProjects.map((client) => client.clientName),
								datasets: [
									{
										label: "# of Projects",
										data: stats.clientProjects.map((client) => client.clientProjects.length),
										borderWidth: 3,
									},
								],
							}}
						/>
					</div>
				</div>
			)}
		</Layout>
	);
}

export default DashboardScreen;
