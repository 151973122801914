import * as t from "../constants/authConstants";
import { requestType } from "../helpers";

export const authReducer = (
  state = {
    user: [],
    signedIn: null,
    loaded: false,
    actionStatus: null,
  },
  action
) => {
  switch (action.type) {
    case requestType(t.SIGN_IN_USER, "SUCCESS"):
      return {
        user: action.payload,
        signedIn: true,
        loaded: true,
      };
    case t.SIGN_OUT_USER:
      return {
        user: action.payload,
        signedIn: false,
      };
    case requestType(t.PUT_USER, "SUCCESS"):
      return {
        ...state,
        user: action.payload,
      };
    case t.NOT_AUTHENTICATED:
      return {
        user: [],
        signedIn: false,
        loaded: false,
      };
    default:
      return state;
  }
};
